import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': merge({
                padding: theme.gutters,
                position: 'relative',
                zIndex: 20,
                fontFamily: theme.typography.fontFamily,
                fontSize: 18,
                color: theme.palette.common.white,
                background: theme.palette.common.darkestBlue,
                [theme.breakpoints.down('sm')]: {
                    paddingTop: 35,
                    fontSize: 16,
                },
            },
            theme.gutter.md.padding.all,
        ),
        '__bottom': {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down(980)]: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textAlign: 'center',
            },
        },
        '__copyright': {
            fontSize: 18,
            [theme.breakpoints.down(980)]: {
                paddingBottom: 10,
                display: 'block',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
            },
        },
        '__rights': {
            paddingLeft: 20,
            position: 'relative',
            display: 'inline-block',
            '&:before': {
                position: 'absolute',
                top: '50%',
                left: 10,
                content: '""',
                display: 'block',
                width: 1,
                height: 12,
                background: theme.palette.common.white,
                transform: 'translateY(-50%)',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
        },
        '__link': {
            marginLeft: 20,
            fontSize: 18,
            textDecoration: 'none',
            color: theme.palette.common.white,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 7.5,
                marginLeft: 10,
                display: 'inline-block',
                fontSize: 15,
            },
        },
    }),
    {
        name: 'Footer',
    },
);
