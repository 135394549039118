import VatValidator from 'eu-vat-validation';
import md5 from 'md5';

const validateVat = (vatNumber) => {
    if (!vatNumber) {
        return false;
    }

    let vatValidator = new VatValidator();

    return vatValidator.validate(vatNumber);
};

const getUserId = (emailAddress) => {
    let hash = 'xmAOZbe55X';

    return md5(emailAddress + hash);
};

export {
    validateVat,
    getUserId,
};
