import React from 'react';
import {useSnackbar} from 'notistack';
import CloseIcon from 'mdi-material-ui/Close';
import {IconButton} from '@material-ui/core';
import useStyles from './SnackbarCloseButton.styles';

export default function ({id}) {
    let classes = useStyles();
    let {closeSnackbar} = useSnackbar();

    return (
        <IconButton
            className={classes['root']}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
                closeSnackbar(id);
            }}
        >
            <CloseIcon />
        </IconButton>
    );
}
