import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': merge({
                zIndex: theme.zIndex.appBar,
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',
                width: '100%',
                height: 200,
                fontFamily: theme.typography.fontFamily,
                [theme.breakpoints.down('md')]: {
                    height: 140,
                },
                [theme.breakpoints.down('sm')]: {
                    height: 70,
                },
            },
            theme.gutter.md.padding.all,
        ),
        '--sticky': merge({
                paddingTop: 0,
                paddingBottom: 0,
                zIndex: 1200,
                position: 'fixed',
                top: 0,
                left: 0,
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                height: 90,
                background: theme.palette.common.darkBlue,
                boxShadow: `0 2px 4px ${theme.palette.common.darkestBlue}`,
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            theme.gutter.md.padding.right,
            theme.gutter.md.padding.left,
        ),
        '__topbar': {
            paddingBottom: 10,
            [theme.breakpoints.down('md')]: {
                paddingBottom: 0,
            },
        },
        '__toggles': {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        '__menu-button': {
            padding: 0,
            position: 'relative',
            width: [50, '!important'],
            minWidth: [0, '!important'],
            height: 40,
        },
        '__menu-icon': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: 30,
            transform: 'translate(-50%, -50%)',
        },
        '--compact': {
            padding: 0,
            '& > *': theme.gutter.sm.margin.left,
        },
    }),
    {
        name: 'Header',
    },
);
