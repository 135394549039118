import React from 'react';
import classNames from 'classnames';
import {FormControl, FormControlLabel, FormGroup} from '@material-ui/core';
import Checkbox from './Checkbox';
import useStyles from './CheckboxGroupField.styles';

export default function ({name, value = [], values, required = false, changeHandler, validationHandler}) {
    let classes = useStyles();

    return (
        <FormControl
            className={classes['root']}
            component="fieldset"
            onChange={changeHandler}
            onBlur={validationHandler}
            required={required}
        >
            <FormGroup>
                {Object.keys(values).map((checkboxValue) => (
                    <FormControlLabel
                        key={checkboxValue}
                        className={(value.indexOf(checkboxValue) !== -1) ? classNames(classes['__item'], classes['__item--active']) : classes['__item']}
                        classes={{
                            label:
                                (value.indexOf(checkboxValue) !== -1)
                                    ? classNames(classes['__label'], classes['__label--active'])
                                    : classes['__label'],
                        }}
                        value={checkboxValue}
                        control={
                            <Checkbox
                                classes={{
                                    root: classes['__checkbox'],
                                }}
                                id={name}
                                checked={value.indexOf(checkboxValue) !== -1}
                            />
                        }
                        label={values[checkboxValue]}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
}
