import {compose, lazy, mount, redirect, withView} from 'navi';
import React from 'react';
import App from './App';
import {withLocale} from 'helpers/route';

export const redirects = {
    'nl': {
        '/voordelen/': '/over-ons/',
        '/probeer-ons-vandaag/': '/',
        '/start-html5-project/': '/',
        '/start-wordpress-project/': '/',
        '/start-responsive-slicing-project/': '/',
        '/betrouwbare-slicing-partner/': '/',
        '/wat-is-basing/': '/slicing/basing/',
        '/psd-slicing/': '/slicing/',
        '/psd-slicing/wat-is-basing/': '/slicing/basing/',
        '/psd-slicing/psd-to-html/': '/slicing/photoshop-psd-to-html-css/',
        '/psd-slicing/psd-to-responsive/': '/slicing/photoshop-psd-to-html-css/',
        '/psd-slicing/psd-to-email/': '/email-development/psd-to-email/',
        '/psd-slicing/psd-to-wordpress/': '/wordpress/photoshop-psd-to-wordpress/',
    },
    'en': {
        '/our-process/': '/how-it-works/',
        '/benefits/': '/how-it-works/',
        '/resources/wordpress/advanced-custom-fields-widget/': '/wordpress/photoshop-psd-to-wordpress/',
        '/en/reliable-slicing-partner/': '/',
        '/start-html5-project/': '/',
        '/start-wordpress-project/': '/',
        '/try-us-today/': '/',
        '/reliable-slicing-partner/': '/',
        '/start-responsive-slicing-project/': '/',
    },
};

export default compose(
    withView((request, context) =>
        <App
            currentUser={context.currentUser}
            appLocale={context.locale}
        />,
    ),
    mount({
        // Default routes
        '/': lazy(() => import('pages/HomePage')),
        '/contact': lazy(() => import('pages/ContactPage')),
        '/disclaimer': lazy(() => import('pages/DisclaimerPage')),
        '/invoices/:invoiceId/:userId': lazy(() => import('pages/InvoicesPage')),
        '/order': lazy(() => import('pages/OrderPage')),
        '/order/:orderId': lazy(() => import('pages/OrderPage/DetailsPage')),
        '/order/:orderId/failure': lazy(() => import('pages/OrderPage/FailurePage')),
        '/order/:orderId/redirect': lazy(() => import('pages/OrderPage/RedirectPage')),
        '/order/:orderId/quote': lazy(() => import('pages/OrderPage/QuotePage')),
        '/order/:orderId/success': lazy(() => import('pages/OrderPage/SuccessPage')),
        '/payments/:paymentId/:userId': lazy(() => import('pages/PaymentsPage')),
        '/privacy-policy': lazy(() => import('pages/PrivacyPolicyPage')),
        '/project/:projectId': lazy(() => import('pages/ProjectPage')),
        '/project/:projectId/view': lazy(() => import('pages/ProjectPage/ViewPage')),
        '/quote/:quoteId': lazy(() => import('pages/QuotePage')),
        '/terms-and-conditions': lazy(() => import('pages/TermsAndConditionsPage')),
        '/user': lazy(() => import('pages/UserPage')),
        '/user/forgotten-password': lazy(() => import('pages/UserPage/ForgottenPasswordPage')),
        '/user/invoices': lazy(() => import('pages/UserPage/InvoicesPage')),
        '/user/invoices/:page': lazy(() => import('pages/UserPage/InvoicesPage')),
        '/user/login': lazy(() => import('pages/UserPage/LoginPage')),
        '/user/projects': lazy(() => import('pages/UserPage/ProjectsPage')),
        '/user/projects/:page': lazy(() => import('pages/UserPage/ProjectsPage')),
        '/user/quotes': lazy(() => import('pages/UserPage/QuotesPage')),
        '/user/quotes/:page': lazy(() => import('pages/UserPage/QuotesPage')),
        '/user/quotes/failure/:quoteId': lazy(() => import('pages/UserPage/QuotesPage/FailurePage')),
        '/user/quotes/view/:quoteId': lazy(() => import('pages/UserPage/QuotesPage/QuotePage')),
        '/user/register': lazy(() => import('pages/UserPage/RegisterPage')),
        '/user/tickets': lazy(() => import('pages/UserPage/TicketsPage')),
        '/user/tickets/new': lazy(() => import('pages/UserPage/TicketsPage/NewPage')),
        '/user/tickets/:page': lazy(() => import('pages/UserPage/TicketsPage')),
        '/user/tickets/view/:ticketId': lazy(() => import('pages/UserPage/TicketsPage/TicketPage')),
        '/example': lazy(() => import('pages/ExamplePage')),

        // English routes
        '/about-us': withLocale({
            'en': lazy(() => import('pages/AboutUsPage')),
            'nl': redirect('/over-ons/'),
        }),
        '/pricing': withLocale({
            'en': lazy(() => import('pages/PricingPage')),
            'nl': redirect('/tarieven/'),
        }),
        '/how-it-works': withLocale({
            'en': lazy(() => import('pages/HowItWorksPage')),
            'nl': redirect('/werkwijze/'),
        }),
        '/support': withLocale({
            'en': lazy(() => import('pages/SupportPage')),
            'nl': redirect('/klantenservice/'),
        }),
        '/support/general': withLocale({
            'en': lazy(() => import('pages/SupportPage/GeneralPage')),
            'nl': redirect('/klantenservice/algemeen/'),
        }),
        '/support/order': withLocale({
            'en': lazy(() => import('pages/SupportPage/OrderPage')),
            'nl': redirect('/klantenservice/bestellen/'),
        }),
        '/support/payment': withLocale({
            'en': lazy(() => import('pages/SupportPage/PaymentPage')),
            'nl': redirect('/klantenservice/betalen/'),
        }),
        '/support/technical': withLocale({
            'en': lazy(() => import('pages/SupportPage/TechnicalPage')),
            'nl': redirect('/klantenservice/techniek/'),
        }),
        '/support/service-warranty': withLocale({
            'en': lazy(() => import('pages/SupportPage/ServiceWarrantyPage')),
            'nl': redirect('/klantenservice/service-garantie/'),
        }),

        // Dutch routes
        '/over-ons': withLocale({
            'en': redirect('/about-us/'),
            'nl': lazy(() => import('pages/AboutUsPage')),
        }),
        '/tarieven': withLocale({
            'en': redirect('/pricing/'),
            'nl': lazy(() => import('pages/PricingPage')),
        }),
        '/werkwijze': withLocale({
            'en': redirect('/how-it-works/'),
            'nl': lazy(() => import('pages/HowItWorksPage')),
        }),
        '/klantenservice': withLocale({
            'en': redirect('/support/'),
            'nl': lazy(() => import('pages/SupportPage')),
        }),
        '/klantenservice/algemeen': withLocale({
            'en': redirect('/support/general/'),
            'nl': lazy(() => import('pages/SupportPage/GeneralPage')),
        }),
        '/klantenservice/bestellen': withLocale({
            'en': redirect('/support/order/'),
            'nl': lazy(() => import('pages/SupportPage/OrderPage')),
        }),
        '/klantenservice/betalen': withLocale({
            'en': redirect('/support/payment/'),
            'nl': lazy(() => import('pages/SupportPage/PaymentPage')),
        }),
        '/klantenservice/techniek': withLocale({
            'en': redirect('/support/technical/'),
            'nl': lazy(() => import('pages/SupportPage/TechnicalPage')),
        }),
        '/klantenservice/service-garantie': withLocale({
            'en': redirect('/support/service-warranty/'),
            'nl': lazy(() => import('pages/SupportPage/ServiceWarrantyPage')),
        }),

        // English only landings
        '/psd-slicing': withLocale({
            'en': lazy(() => import('pages/en/PsdSlicingPage')),
        }),
        '/psd-slicing/psd-to-html': withLocale({
            'en': lazy(() => import('pages/en/PsdToHtmlPage')),
        }),
        '/psd-slicing/psd-to-wordpress': withLocale({
            'en': lazy(() => import('pages/en/PsdToWordPressPage')),
        }),
        '/psd-slicing/psd-to-email': withLocale({
            'en': lazy(() => import('pages/en/PsdToEmailPage')),
        }),
        '/psd-slicing/psd-to-responsive': withLocale({
            'en': lazy(() => import('pages/en/PsdToResponsivePage')),
        }),

        // Dutch only landings
        '/slicing': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage')),
        }),
        '/slicing/basing': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/BasingPage')),
        }),

        '/slicing/adobe-xd-to-html': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/AdobeXdToHtmlPage')),
        }),
        '/slicing/figma-to-html': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/FigmaToHtmlPage')),
        }),
        '/slicing/photoshop-psd-to-html-css': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/PhotoshopPsdToHtmlCssPage')),
        }),
        '/slicing/photoshop-psd-to-html-css/psd-to-bootstrap': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/PhotoshopPsdToHtmlCssPage/PsdToBootstrapPage')),
        }),
        '/slicing/sketch-to-html': withLocale({
            'nl': lazy(() => import('pages/nl/SlicingPage/SketchToHtmlPage')),
        }),
        '/email-development/psd-to-email': withLocale({
            'nl': lazy(() => import('pages/nl/EmailDevelopmentPage/PsdToEmailPage')),
        }),
        '/wordpress/photoshop-psd-to-wordpress': withLocale({
            'nl': lazy(() => import('pages/nl/WordpressPage/PhotoshopPsdToWordpressPage')),
        }),
    }),
);
