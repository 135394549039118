import React from 'react';
import GoogleIcon from 'mdi-material-ui/Google';
import {FormattedMessage} from 'react-intl';
import {Button} from '@material-ui/core';
import {isEmpty} from 'lodash';
import useStyles from './GoogleButton.styles';
import {useCurrentUser} from 'context';
import {auth, firebase, firestore} from 'libs/firebase';
import {logError} from 'helpers/debug';
import {useSnackbar} from 'notistack';
import useEvent from 'hooks/useEvent';

export default function ({label = false, register = false, reauthenticate = false, reauthenticateHandler = false}) {
    let classes = useStyles();
    let {currentUser} = useCurrentUser();
    let {enqueueSnackbar} = useSnackbar();
    let {createEvent} = useEvent();

    if (!isEmpty(currentUser) && !reauthenticate) {
        return null;
    }

    if (!label) {
        label = <FormattedMessage
            id="components.GoogleButton.loginWithGoogle"
            defaultMessage={`Login with Google`}
        />;
    }

    return (
        <Button
            className={classes['root']}
            onClick={async () => {
                const googleProvider = new firebase.auth.GoogleAuthProvider();

                try {
                    await auth.signInWithPopup(googleProvider);

                    // Reauthenticate for sensitive profile changes
                    if (typeof reauthenticateHandler === 'function') {
                        reauthenticateHandler(true, true);
                    }

                    if (register) {
                        // Registered
                        createEvent('REGISTERED');

                        firestore.doc(`core/counters`).update({
                            teamCount: firebase.firestore.FieldValue.increment(1),
                        });
                    } else {
                        // Logged in
                        createEvent('LOGGED_IN');
                    }
                } catch (error) {
                    if (logError(error)) {
                        enqueueSnackbar(error.message, {
                            variant: 'error',
                        });
                    }
                }
            }}
            variant="contained"
            fullWidth
            size="small"
            color="primary"
        >
            <GoogleIcon className={classes['__icon']} />
            {label}
        </Button>
    );
}
