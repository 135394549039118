import {map, NotFoundError, redirect} from 'navi';
import {useCurrentRoute, useNavigation} from 'react-navi';
import React from 'react';
import {isEmpty} from 'lodash';

const withCurrentRoute = (Component) => {
    return function WrapperComponent(props) {
        let navigation = useNavigation();
        let route = useCurrentRoute();

        if (
            route && route.lastChunk && route.lastChunk.request &&
            route.lastChunk.request.params
        ) {
            route.url.params = route.lastChunk.request.params;
        }

        return (
            <Component
                {...props}
                route={route}
                navigation={navigation}
            />
        );
    };
};

const withAuthentication = (matcher) => {
    return map((request, context) => {
        // Only proceed when logged in
        if (!isEmpty(context.currentUser)) {
            return matcher;
        }

        return redirect('/user/login/?from=' + encodeURIComponent(request.mountpath + request.search));
    });
};

const withoutAuthentication = (matcher) => {
    return map((request, context) => {
        // Only proceed when logged out
        if (isEmpty(context.currentUser)) {
            return matcher;
        }

        // If we are from another page redirect back when we have an authentication state
        if (request.query && request.query.from) {
            return redirect(decodeURIComponent(request.query.from));
        }

        if (
            context.currentUser.email && (
                request.originalUrl === '/user/register/' ||
                request.originalUrl === '/user/login/'
            )
        ) {
            return redirect('/user/');
        }

        return redirect('/');
    });
};

const withLocale = (matchers) => {
    return map((request, context) => {
        if (matchers[context.locale]) {
            return matchers[context.locale];
        }

        // Throw 404 when nothing found for current locale
        throw new NotFoundError(request.originalUrl);
    });
};

export {
    withCurrentRoute,
    withAuthentication,
    withoutAuthentication,
    withLocale,
};
