export default {
    firebase: {
        apiKey: 'AIzaSyBwWgDW4qayHcMY_t4NaP5SdmiXF_eeTOM',
        authDomain: 'direct-basing.firebaseapp.com',
        databaseURL: 'https://direct-basing.firebaseio.com',
        projectId: 'direct-basing',
        storageBucket: 'direct-basing.appspot.com',
        messagingSenderId: '253684882449',
        appId: '1:253684882449:web:3e8b1e3795124cd21565b0',
        measurementId: 'G-9KNCNCDGLM',
    },
    previews: {
        url: 'https://accept.project-example.com',
    },
    google: {
        tagManager: {
            en: 'GTM-WTD64FK',
            nl: 'GTM-N7XVSCG',
        },
    },
    developers: {
        '557058:7b0a96d5-972d-4fa5-bd57-6a5280b14da8': {
            'name': 'Alex',
        },
        '557058:12b20b8f-7ceb-4c4f-91a8-c1d3a7a591d3': {
            'name': 'Sachin',
        },
        '5c04b08b55c77c509122f88c': {
            'name': 'Sumit',
        },
        '5d30049a917eeb0c13b4f3c7': {
            'name': 'Gautam',
        },
    },
    upload: {
        allowedExtensions: [
            '.jpg',
            '.jpeg',
            '.gif',
            '.png',
            '.psd',
            '.txt',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.ai',
            '.ind',
            '.zip',
            '.rar',
            '.pdf',
            '.indd',
            '.rtf',
            '.ttf',
            '.ttc',
            '.pfb',
            '.pfm',
            '.otf',
            '.dfont',
            '.pfa',
            '.afm',
            '.eot',
            '.woff',
            '.tfm',
            '.inf',
            '.log',
            '.sketch',
            '.xd',
            '.fig',
        ],
    },
};
