import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@material-ui/core';
import useStyles from './LoginButton.styles';
import {isEmpty} from 'lodash';
import {FormattedMessage} from 'react-intl';
import GoogleButton from 'components/GoogleButton';
import LoginForm from 'components/LoginButton/LoginForm';
import {Link} from 'react-navi';
import LockIcon from 'mdi-material-ui/Lock';
import {useCurrentUser} from 'context';
import {Close} from 'mdi-material-ui';

export default function LoginButton({buttonClass = '', icon = false, label = false, handlePopup, reauthenticate = false, reauthenticateHandler = false}) {
    let classes = useStyles();
    let {authenticating, currentUser} = useCurrentUser();
    let [loginFormOpen, setLoginFormOpen] = useState(false);

    if (
        authenticating ||
        (!isEmpty(currentUser) && !reauthenticate)
    ) {
        return (
            <></>
        );
    }

    if (!icon) {
        icon = <LockIcon fontSize="inherit" />;
    }

    if (!label) {
        label = <FormattedMessage
            id="components.LoginButton.loginForClients"
            defaultMessage={`Login for clients`}
        />;
    }

    return (
        <span className={classes['root']}>
            <Button
                id="loginButtonHeader"
                className={(buttonClass) ? buttonClass : classes['__button']}
                variant="contained"
                size="small"
                onClick={() => setLoginFormOpen(true)}
            >
                {icon} {label}
            </Button>
            <Dialog
                open={loginFormOpen}
                onClose={() => setLoginFormOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    className={classes['__title']}
                    id="form-dialog-title"
                >
                    {(reauthenticate) ? (
                        <FormattedMessage
                            id="components.LoginButton.reAuth"
                            defaultMessage={`Re-authenticate`}
                        />
                    ) : (
                        <FormattedMessage
                            id="components.LoginButton.clientpanel"
                            defaultMessage={`Clientpanel`}
                        />
                    )}
                    <IconButton
                        className={classes['__close']}
                        onClick={() => setLoginFormOpen(false)}
                    >
                    <Close />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                    {(reauthenticate) ? (
                        <Typography className={classes['__description']}>
                            <FormattedMessage
                                id="components.LoginButton.reauthenticateDescription"
                                defaultMessage={`Some security-sensitive actions such as changing your email address or changing your password require you to re-enter your credentials.`}
                            />
                        </Typography>
                    ) : null}
                    <GoogleButton
                        reauthenticate={reauthenticate}
                        reauthenticateHandler={reauthenticateHandler}
                    />
                    <Typography className={classes['__or']}>
                        <FormattedMessage
                            id="components.LoginButton.or"
                            defaultMessage={`or`}
                        />
                    </Typography>
                    <LoginForm
                        popupHandler={handlePopup}
                        reauthenticate={reauthenticate}
                        reauthenticateHandler={reauthenticateHandler}
                    />
                </DialogContent>
                <DialogActions>
                    {(!reauthenticate) ? (
                        <Typography className={classes['__links']}>
                            <Link
                                href="/user/register/"
                                onClick={() => setLoginFormOpen(false)}
                            >
                                <FormattedMessage
                                    id="components.LoginButton.register"
                                    defaultMessage={`Register`}
                                />
                            </Link>
                            <br />
                            <Link
                                href="/user/forgotten-password/"
                                onClick={() => setLoginFormOpen(false)}
                            >
                                <FormattedMessage
                                    id="components.LoginButton.forgottenPassword"
                                    defaultMessage={`Forgotten Password`}
                                />
                            </Link>
                        </Typography>
                    ) : null}
                    <Button
                        className={classes['__cancel']}
                        onClick={() => setLoginFormOpen(false)}
                        variant="contained"
                        size="small"
                        color="inherit"
                    >
                        <FormattedMessage
                            id="components.LoginButton.cancel"
                            defaultMessage={`Cancel`}
                        />
                    </Button>
                    <Button
                        onClick={() => {
                            document.getElementById('loginFormButton').click();
                        }}
                        variant="contained"
                        size="small"
                        color="secondary"
                    >
                        <FormattedMessage
                            id="components.LoginButton.login"
                            defaultMessage={`Login`}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
