import React from 'react';
import {Input, MenuItem, Select} from '@material-ui/core';
import useStyles from './SelectGroupField.styles';

export default function ({name, value, values, required = false, changeHandler, validationHandler}) {
    let classes = useStyles();

    return (
        <div className={classes['root']}>
            <Select
                className={classes['__select']}
                classes={{
                    root: classes['__select-root'],
                    select: classes['__select-input'],
                }}
                onChange={changeHandler}
                onBlur={validationHandler}
                value={value}
                required={required}
                input={<Input
                    name={name}
                    disableUnderline={true}
                />}
            >
                {Object.keys(values).map((selectValue) => (
                    <MenuItem
                        key={selectValue}
                        value={selectValue}
                    >
                        {values[selectValue]}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
