import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/remote-config';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import coreConfig from 'configs/Core.config';

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(coreConfig.firebase) : firebase.app();
const performance = (typeof window !== 'undefined') ? firebase.performance() : false;
const analytics = (typeof window !== 'undefined') ? firebase.analytics() : false;
const auth = firebase.auth();
const functions = firebase.functions();
const firestore = firebase.firestore(firebaseApp);
const remoteConfig = (typeof window !== 'undefined') ? firebase.remoteConfig() : false;

firestore.settings({});

if (typeof window !== 'undefined') {
    firestore.enablePersistence({synchronizeTabs: true})
        .catch((error) => {
            console.error(error);
        });

    if (window.location.host.includes('localhost')) {
        functions.useFunctionsEmulator('http://localhost:5000');
    }

    remoteConfig.settings = {
        minimumFetchIntervalMillis: 900000,
        defaultConfig: {
            debug: false,
        },
    };

    remoteConfig.fetchAndActivate()
        .catch((error) => {
            console.error('Remote config error: ', error);
        });
}

export {
    firebase,
    analytics,
    performance,
    auth,
    functions,
    firestore,
    remoteConfig,
};
