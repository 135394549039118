import React from 'react';

const RadioUnChecked = (props) => (
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g id="Design" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <g
                id="order-now---form"
                transform="translate(-257.000000, -745.000000)"
                stroke="#9FA2BD"
                fill="#FFFFFF"
            >
                <g id="steps" transform="translate(-148.000000, 433.000000)">
                    <g
                        id="1.-projectdetails"
                        transform="translate(300.000000, 58.000000)"
                    >
                        <g id="form" transform="translate(31.000000, 0.000000)">
                            <g id="markup" transform="translate(2.000000, 56.000000)">
                                <g id="responsive" transform="translate(50.000000, 179.000000)">
                                    <circle id="Oval-4" cx={38} cy={35} r={15}/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default RadioUnChecked;
