import React, {useEffect, useState} from 'react';
import {Tabs} from '@material-ui/core';
import useStyles from './MainMenu.styles';
import MenuLink from 'components/MenuLink';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCurrentRoute} from 'react-navi';

export default function MainMenu() {
    let classes = useStyles();
    let intl = useIntl();
    let route = useCurrentRoute();
    let [selected, setSelected] = useState(false);

    useEffect(() => {
        if (['/pricing/', '/tarieven/'].indexOf(route.url.pathname) !== -1) {
            setSelected(0);
        } else if (['/how-it-works/', '/werkwijze/'].indexOf(route.url.pathname) !== -1) {
            setSelected(1);
        } else if (['/about-us/', '/over-ons/'].indexOf(route.url.pathname) !== -1) {
            setSelected(2);
        } else if (['/contact/'].indexOf(route.url.pathname) !== -1) {
            setSelected(3);
        } else {
            setSelected(false);
        }
    }, [route.url.pathname]);

    let links = [
        {
            label: <FormattedMessage
                id="components.Header.MainMenu.pricing"
                defaultMessage={`Pricing`}
            />,
            url: intl.formatMessage({
                id: 'url.pricing',
                defaultMessage: '/pricing/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Header.MainMenu.howItWorks"
                defaultMessage={`How it works`}
            />,
            url: intl.formatMessage({
                id: 'url.howItWorks',
                defaultMessage: '/how-it-works/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Header.MainMenu.aboutUs"
                defaultMessage={`About us`}
            />,
            url: intl.formatMessage({
                id: 'url.aboutUs',
                defaultMessage: '/about-us/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Header.MainMenu.contact"
                defaultMessage={`Contact`}
            />,
            url: '/contact/',
        },
    ];

    return (
        <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={selected}
            className={classes['root']}
        >
            {links.map((link, index) => (
                <MenuLink
                    key={index}
                    label={link.label}
                    url={link.url}
                />
            ))}
        </Tabs>
    );
}
