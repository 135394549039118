import React from 'react';
import {Checkbox} from '@material-ui/core';
import CheckboxChecked from './CheckboxChecked';
import CheckboxUnchecked from './CheckboxUnchecked';

export default function (props) {
    return (
        <Checkbox
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />} {...props}
        />
    );
}
