import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': {
            padding: 12,
            width: '100%',
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white,
        },
        '__uploader': merge({
                outline: 'none',
                overflow: 'hidden',
                width: '100%',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: 18,
                color: [theme.palette.common.blue, '!important'],
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.primary.light,
            },
            theme.gutter.sm.padding.all,
        ),
        '--active': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '__loader': {
            background: theme.palette.primary.main,
        },
        '__loader-bg': {
            background: theme.palette.primary.light,
        },
        '__file': {
            padding: '10px 0 0',
            overflow: 'hidden',
            width: '100%',
        },
        '__file-info': {
            paddingBottom: 5,
            display: 'flex',
            justifyContent: 'space-between',
        },
        '__file-name': {
            fontSize: 16,
        },
        '__file-delete': {
            cursor: 'pointer',
            color: theme.palette.secondary.main,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.secondary.dark,
                },
            },
        },
        '__completed': {
            display: 'block',
            width: '100%',
            height: 5,
            background: theme.palette.primary.main,
        },
    }),
    {
        name: 'UploadField',
    },
);
