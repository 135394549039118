import React from 'react';
import classNames from 'classnames';
import {FormControl, FormHelperText, FormLabel} from '@material-ui/core';
import useStyles from './Fieldset.styles';

export default function ({className, label, required, description, helper, hasError = false, fullWidth = false, noBottom = false, children}) {
    let classes = useStyles();

    if (!className) {
        className = classes['root'];

        if (noBottom) {
            className = classNames(classes['root'], classes['--no-bottom']);
        }
    }

    return (
        <FormControl
            className={className}
            component="fieldset"
            error={hasError}
            required={required}
            fullWidth={fullWidth}
        >
            <FormLabel
                className={classes['__title']}
                component="legend"
            >
                {label}
            </FormLabel>
            {(description) ? (
                <FormHelperText className={classes['__description']}>{description}</FormHelperText>
            ) : null}
            {children}
            {(helper) ? (
                <FormHelperText className={classes['__helper']}>{helper}</FormHelperText>
            ) : null}
        </FormControl>
    );
}
