import React from 'react';
import {Link} from 'react-navi';
import {Tab} from '@material-ui/core';
import useStyles from './MenuLink.styles';

export default function ({label, url}) {
    let classes = useStyles();

    return (
        <Tab
            classes={{
                root: classes['root'],
            }}
            label={label}
            component={Link}
            href={url}
        />
    );
}
