import React from 'react';
import useStyles from './Footer.styles';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-navi';
import QuickLinks from './QuickLinks';

export default function Footer() {
    let classes = useStyles();

    return (
        <footer
            id="footer"
            className={classes['root']}
        >
            <QuickLinks />
            <div className={classes['__bottom']}>
                <small className={classes['__copyright']}>
                    Copyright &copy; 2008 - {new Date().getFullYear()} Direct Basing B.V.
                    <span className={classes['__rights']}>
                    <FormattedMessage
                        id="components.Footer.allRightsReserved"
                        defaultMessage={`All rights reserved`}
                    />
                </span>
                </small>
                <div className={classes['__links']}>
                    <Link
                        className={classes['__link']}
                        href="/disclaimer/"
                    >
                        <FormattedMessage
                            id="components.Footer.disclaimer"
                            defaultMessage={`Disclaimer`}
                        />
                    </Link>
                    <Link
                        className={classes['__link']}
                        href="/privacy-policy/"
                    >
                        <FormattedMessage
                            id="components.Footer.privacyPolicy"
                            defaultMessage={`Privacy Policy`}
                        />
                    </Link>
                    <Link
                        className={classes['__link']}
                        href="/terms-and-conditions/"
                    >
                        <FormattedMessage
                            id="components.Footer.termsAndConditions"
                            defaultMessage={`Terms and Conditions`}
                        />
                    </Link>
                </div>
            </div>
        </footer>
    );
}
