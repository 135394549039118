import React, {useState} from 'react';
import DeleteForeverIcon from 'mdi-material-ui/DeleteForever';
import {LinearProgress} from '@material-ui/core';
import useStyles from './UploadField.styles';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import {concat} from 'lodash';
import {formatBytes} from 'helpers/form';

export default function ({value = [], config, uploadHandler, removeHandler}) {
    let classes = useStyles();
    let [filesQue, setFilesQue] = useState([]);
    let [processedFiles, setProcessedFiles] = useState(value);

    const filesHandler = (files) => {
        let updatedQue = filesQue;

        files.forEach((file) => {
            if (filesQue[file.name]) {
                delete updatedQue[file.name];
            }
        });

        setFilesQue(updatedQue);
        setProcessedFiles(files);
    };

    let {getRootProps, getInputProps, isDragActive} = useDropzone(Object.assign(config, {
        onDrop: (acceptedFiles) => {
            setFilesQue(concat(filesQue, acceptedFiles));

            acceptedFiles.forEach((file) => {
                uploadHandler(file, filesHandler);
            });
        },
    }));

    return (
        <div className={classes['root']}>
            <div {...getRootProps({
                className: (isDragActive) ? classNames(classes['__uploader'], classes['--active']) : classes['__uploader'],
            })}>
                <input {...getInputProps()} />
                {config.message}
            </div>
            {filesQue && Object.keys(filesQue).map((key, index) => {
                let file = filesQue[key];

                return (
                    <div
                        key={index}
                        className={classes['__file']}
                    >
                        <div className={classes['__file-info']}>
                            <div className={classes['__file-name']}>{file.name}</div>
                            <div className={classes['__file-size']}>{formatBytes(file.size)}</div>
                        </div>
                        <LinearProgress
                            classes={{
                                barColorPrimary: classes['__loader'],
                                colorPrimary: classes['__loader-bg'],
                            }}
                        />
                    </div>
                );
            })}
            {processedFiles && Object.keys(processedFiles).map((key, index) => {
                let file = processedFiles[key];

                return (
                    <div
                        key={index}
                        className={classes['__file']}
                    >
                        <div className={classes['__file-info']}>
                            <div className={classes['__file-name']}>{file.name}</div>
                            <span
                                className={classes['__file-delete']}
                                onClick={() => removeHandler(file)}
                            >
                                    <DeleteForeverIcon fontSize="small" />
                                </span>
                        </div>
                        <div className={classes['__completed']}>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
