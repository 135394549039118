import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: 'auto',
        },
        '__title': {
            position: 'relative',
        },
        '__close': {
            position: 'absolute',
            top: '50%',
            right: 10,
            transform: 'translateY(-50%)',
            [theme.breakpoints.down('sm')]: {
                right: 0,
            },
            '& svg': {
                fill: theme.palette.common.white,
            },
        },
        '__description': theme.gutter.sm.margin.bottom,
        '__button': {
            color: theme.palette.common.white,
            backgroundColor: 'transparent',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    backgroundColor: theme.palette.common.darkBlue,
                },
            },
            '& svg': {
                marginRight: 10,
            },
        },
        '__or': {
            padding: '10px 0',
            display: 'block',
            position: 'relative',
            textAlign: 'center',
            color: theme.palette.common.blue,
        },
        '__links': {
            marginRight: 'auto',
            '& a': {
                textDecoration: 'underline',
                color: theme.palette.common.blue,
                [theme.breakpoints.up('md')]: {
                    '&:hover': {
                        textDecoration: 'none',
                    },
                },
            },
        },
        '__cancel': {
            color: [theme.palette.common.blue, '!important'],
            background: theme.palette.common.lightGray,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    background: theme.palette.common.gray,
                },
            },
        },
    }),
    {
        name: 'LoginButton',
    },
);
