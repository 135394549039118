import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: '100%',
        },
        '__item': {
            margin: '0 0 15px 0',
            alignItems: 'flex-start',
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white,
        },
        '__item--active': {
            background: theme.palette.common.purple,
        },
        '__label': {
            padding: '14px 12px 14px 0',
            width: '100%',
            fontSize: 18,
            color: theme.palette.common.darkBlue,
            '& strong': {
                float: 'right',
            },
            '& em': {
                paddingTop: 10,
                display: 'block',
                lineHeight: 1.3,
                fontStyle: 'normal',
                fontSize: 16,
            },
        },
        '__label--active': {
            color: theme.palette.common.white,
        },
        '__checkbox': {
            marginTop: 1,
            padding: 9 + 3,
        },
    }),
    {
        name: 'CheckboxGroupField',
    },
);
