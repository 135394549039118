import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: '100%',
        },
        '__button': {
            background: theme.palette.primary.main,
        },
        '--light': {
            background: theme.palette.common.white,
        },
    }),
    {
        name: 'SnackbarCloseButton',
    },
);
