import {auth, remoteConfig} from 'libs/firebase';

const logError = (error) => {
    if (
        remoteConfig.getBoolean('debug') || (
            error.message.indexOf('Missing or insufficient permissions') === -1 &&
            error.message.indexOf('No Result') === -1
        )
    ) {
        console.log('=== START DEBUG ===');
        console.log('URL:', window.location.href);
        console.log('LOGGED IN:', !!auth.currentUser);

        if (!!auth.currentUser) {
            console.log('USER ID:', auth.currentUser.uid);

        }

        console.log('ERROR:', error);
        console.log('==== END DEBUG ====');

        return true;
    }

    return false;
};

export {
    logError,
};
