import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
    '@global': {
        ':focus': {
            outline: 'none',
        },
    },
        'root': {
            overflowX: 'hidden',
            width: '100%',
            fontFamily: theme.typography.body1.fontFamily,
        },
        'snackbar__success': merge({
                color: theme.palette.success.contrastText,
                background: theme.palette.success.main,
                [theme.breakpoints.down('sm')]: {
                    position: 'absolute',
                    bottom: 75,
                },
            },
            theme.typography.body2,
        ),
        'snackbar__warning': merge({
                color: theme.palette.warning.contrastText,
                background: theme.palette.warning.main,
                [theme.breakpoints.down('sm')]: {
                    position: 'absolute',
                    bottom: 75,
                },
            },
            theme.typography.body2,
        ),
        'snackbar__error': merge({
                color: theme.palette.error.contrastText,
                background: theme.palette.error.main,
                [theme.breakpoints.down('sm')]: {
                    position: 'absolute',
                    bottom: 75,
                },
            },
            theme.typography.body2,
        ),
        'snackbar__info': merge({
                color: theme.palette.info.contrastText,
                background: theme.palette.info.main,
                [theme.breakpoints.down('sm')]: {
                    position: 'absolute',
                    bottom: 75,
                },
            },
            theme.typography.body2,
        ),
        'snackbar__icon': {
            marginRight: 15,
        },
    }),
);
