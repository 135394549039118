import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            position: 'relative',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                '-webkit-overflow-scrolling': 'touch',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
    }),
    {
        name: 'MobileWrapper',
    },
);
