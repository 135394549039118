import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {SnackbarProvider} from 'notistack';
import {NotFoundBoundary, useCurrentRoute, useLoadingRoute, useNavigation, View} from 'react-navi';
import LoadingBar from 'components/LoadingBar';
import BottomMenu from 'components/BottomMenu';
import Main from 'components/Main';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MobileWrapper from 'components/MobileWrapper';
import TagManager from 'react-gtm-module';
import NotFound from 'components/NotFound';
import SnackbarCloseButton from 'components/SnackbarCloseButton';
import useStyles from 'App.styles';
import AppHead from 'App.head';
import useCurrentUser from 'hooks/useCurrentUser';
import useTheme from 'hooks/useTheme';
import useLocale from 'hooks/useLocale';
import {CurrentUserContext, LocaleContext, ThemeContext} from 'context';

import messagesEn from 'translations/en.json';
import messagesNl from 'translations/nl.json';
import CoreConfig from 'configs/Core.config';

const messages = {
    'en': messagesEn,
    'nl': messagesNl,
};

export default function App({appLocale}) {
    let navigation = useNavigation();
    let route = useCurrentRoute();
    let {theme, setTheme} = useTheme();
    let {locale, setLocale} = useLocale(appLocale);
    let {authenticating, currentUser, setCurrentUser} = useCurrentUser();
    let loading = !!useLoadingRoute();
    let classes = useStyles();
    let [currentUserContext, setCurrentUserContext] = useState({
        authenticating: authenticating,
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
    });
    let [localeContext, setLocaleContext] = useState({
        locale: locale,
        setLocale: setLocale,
    });
    let [themeContext, setThemeContext] = useState({
        theme: theme,
        setTheme: setTheme,
    });

    useEffect(() => {
        let jssStyles = document.getElementById('jss-server-side');

        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    useEffect(() => {
        setCurrentUserContext({
            authenticating: authenticating,
            currentUser: currentUser,
            setCurrentUser: setCurrentUser,
        });

        navigation.setContext({
            locale: locale,
            currentUser: currentUser,
        });

        TagManager.initialize({
            gtmId: CoreConfig.google.tagManager[locale],
            dataLayer: {
                userId: currentUser.userId,
            },
        });
    }, [locale, authenticating, currentUser, setCurrentUser, navigation]);

    useEffect(() => {
        setLocaleContext({
            locale: locale,
            setLocale: setLocale,
        });
    }, [locale, setLocale]);

    useEffect(() => {
        setThemeContext({
            theme: theme,
            setTheme: setTheme,
        });
    }, [theme, setTheme]);

    useEffect(() => {
        if (currentUser.language && locale !== currentUser.language) {
            setLocale(currentUser.language);
        }
    }, [currentUser.language, setLocale, locale]);

    useEffect(() => {
        let mobileWrapper = document.getElementById('mobileWrapper');

        window.scrollTo(0, 0);

        if (mobileWrapper) {
            mobileWrapper.scrollTo(0, 0);
        }
    }, [route.url.pathname]);

    return (
        <CurrentUserContext.Provider value={currentUserContext}>
            <LocaleContext.Provider value={localeContext}>
                <ThemeContext.Provider value={themeContext}>
                    <IntlProvider
                        locale={locale}
                        timeZone="Europe/Amsterdam"
                        messages={messages[locale]}
                    >
                        <SnackbarProvider
                            maxSnack={3}
                            autoHideDuration={2000}
                            classes={{
                                variantSuccess: classes['snackbar__success'],
                                variantError: classes['snackbar__error'],
                                variantWarning: classes['snackbar__warning'],
                                variantInfo: classes['snackbar__info'],
                            }}
                            action={(key) => <SnackbarCloseButton id={key} />}
                        >
                            <AppHead locale={locale} />
                            <LoadingBar
                                loading={!!loading}
                            />
                            {(theme === 'inspection') ? (
                                <NotFoundBoundary
                                    render={() => (
                                        <NotFound />
                                    )}
                                >
                                    <View />
                                </NotFoundBoundary>
                            ) : (
                                <div className={classes['root']}>
                                    <BottomMenu />
                                    <MobileWrapper>
                                        <Header />
                                        <Main theme={theme}>
                                            <NotFoundBoundary
                                                render={() => (
                                                    <NotFound />
                                                )}
                                            >
                                                <View />
                                            </NotFoundBoundary>
                                        </Main>
                                        <Footer theme={theme} />
                                    </MobileWrapper>
                                </div>
                            )}
                        </SnackbarProvider>
                    </IntlProvider>
                </ThemeContext.Provider>
            </LocaleContext.Provider>
        </CurrentUserContext.Provider>
    );
}
