import {useCallback, useEffect, useState} from 'react';

export default function useWindowSize() {
    let [windowSize, setState] = useState({
        width: 0,
        height: 0,
        standalone: false,
        deviceWidth: 0,
        deviceHeight: 0,
        devicePixelRatio: 1,
    });

    useEffect(() => {
        function onChange() {
            setState({
                width: window.innerWidth,
                height: window.innerHeight,
                standalone: window.navigator.standalone,
                deviceWidth: window.screen.width,
                deviceHeight: window.screen.height,
                devicePixelRatio: window.devicePixelRatio,
            });
        }

        setState({
            width: window.innerWidth,
            height: window.innerHeight,
            standalone: window.navigator.standalone,
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            devicePixelRatio: window.devicePixelRatio,
        });

        window.addEventListener('resize', onChange);
        window.addEventListener('load', onChange);
        document.addEventListener('visibilitychange', onChange);

        return () => {
            window.removeEventListener('resize', onChange);
            window.removeEventListener('load', onChange);
            document.addEventListener('visibilitychange', onChange);
        };
    }, []);

    let setWindowSize = useCallback(
        (windowSize) => {
            setState(windowSize);
        }, [],
    );

    return {
        windowSize,
        setWindowSize,
    };
};
