import {createMuiTheme} from '@material-ui/core/styles';
import {betweenWidth, getBreakpoints, getGutter, maxWidth, minWidth} from 'helpers/styles';

const theme = createMuiTheme({
    breakpoints: {
        values: getBreakpoints,
    },
    palette: {
        common: {
            black: '#000',
            white: '#fff',
            lightGray: '#f3f3f3',
            gray: '#e7e5e5',
            lightPurple: '#f4f5fc',
            purple: '#9fa2bd',
            darkPurple: '#6d708d',
            blue: '#313343',
            darkBlue: '#242633',
            darkerBlue: '#1c1d28',
            darkestBlue: '#0f1019',
            transparentBlue: 'rgba(36,38,51,0.95)',
        },
        primary: {
            light: '#dbefcc',
            main: '#73c23a',
            dark: '#60b42c',
        },
        secondary: {
            light: '#f49359',
            main: '#ef6412',
            dark: '#eb520d',
        },
        success: {
            light: '#c8e6c9',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#fff',
        },
        info: {
            light: '#bbdefb',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#fff',
        },
        warning: {
            light: '#ffe0b2',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: '#fff',
        },
        error: {
            light: '#e57373',
            main: '#be0404',
            dark: '#be0404',
            contrastText: '#fff',
        },
        text: {
            primary: '#313343',
            secondary: '#9fa2bd',
        },
        background: {
            default: '#252734',
        },
    },
    shape: {
        borderRadius: 6,
    },
    typography: {
        fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 400,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        heading: {
            fontFamily: '"Source Serif Pro", "Helvetica", "Arial", sans-serif',
            fontWeight: 700,
            lineHeight: 1.1,
        },
    },
});

theme.widthBase = 1500;

theme.gutters = {
    xs: 15,
    sm: 25,
    md: 50,
    lg: 75,
    xl: 150,
};

theme.breakpoints.down = maxWidth;
theme.breakpoints.up = minWidth;
theme.breakpoints.between = betweenWidth;

theme.gutter = {
    xs: getGutter(theme, theme.gutters.xs, {
        md: 10,
        sm: 5,
    }),
    sm: getGutter(theme, theme.gutters.sm, {
        md: 15,
        sm: 10,
    }),
    md: getGutter(theme, theme.gutters.md, {
        md: 25,
        sm: 15,
    }),
    lg: getGutter(theme, theme.gutters.lg, {
        md: 50,
        sm: 25,
    }),
    xl: getGutter(theme, theme.gutters.xl, {
        md: 75,
        sm: 50,
    }),
};

theme.typography = Object.assign(theme.typography, {
    h1: Object.assign({
        fontSize: 75,
        color: theme.palette.common.blue,
        [theme.breakpoints.down('md')]: {
            fontSize: 60,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 50,
        },
    }, theme.typography.heading),
    h2: Object.assign({
        fontSize: 60,
        color: theme.palette.common.blue,
        [theme.breakpoints.down('md')]: {
            fontSize: 50,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    }, theme.typography.heading),
    h3: Object.assign({
        fontSize: 40,
        color: theme.palette.common.blue,
        [theme.breakpoints.down('md')]: {
            fontSize: 35,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    }, theme.typography.heading),
    h4: Object.assign({
        fontSize: 30,
        color: theme.palette.common.blue,
        [theme.breakpoints.down('sm')]: {
            fontSize: 27.5,
        },
    }, theme.typography.heading),
    h5: Object.assign({
        fontSize: 24,
        color: theme.palette.common.blue,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    }, theme.typography.heading),
    h6: Object.assign({
        fontSize: 20,
        color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    }, theme.typography.heading),
    subtitle1: {
        lineHeight: 1.5,
        fontFamily: theme.typography.heading.fontFamily,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 17,
        },
    },
    body1: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    body2: {
        fontSize: 18,
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('sm')]: {
            fontSize: 17,
        },
    },
    button: {
        fontSize: 24,
        textTransform: 'none',
        fontWeight: 600,
    },
});

theme.overrides = {
    MuiButton: {
        root: {
            padding: '8px 16px',
            whiteSpace: 'nowrap',
            lineHeight: '42px',
            fontFamily: theme.typography.fontFamily,
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                padding: '6px 14px 7px',
                fontSize: 21,
            },
            [theme.breakpoints.down(350)]: {
                lineHeight: '28px',
            },
        },
        sizeSmall: {
            padding: '8px 12px',
            minWidth: 64,
            minHeight: 38,
            lineHeight: '28px',
            fontSize: 16,
            textTransform: 'uppercase',
        },
        contained: {
            color: [theme.palette.common.white, '!important'],
            textTransform: 'none !important',
            backgroundColor: theme.palette.common.purple,
            boxShadow: 'none',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    backgroundColor: theme.palette.common.darkPurple,
                },
            },
        },
        containedPrimary: {
            color: theme.palette.common.white,
        },
        containedSecondary: {
            color: theme.palette.common.white,
        },
    },
    MuiDialogContentText: {
        root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            color: theme.palette.common.blue,
        },
    },
    MuiModal: {
        root: {
            fontFamily: theme.typography.fontFamily,
        },
    },
    MuiInputBase: {
        input: {
            '&::-webkit-input-placeholder': {
                opacity: 1,
                color: theme.palette.common.blue,
            },
            '&::-moz-placeholder': {
                opacity: 1,
                color: theme.palette.common.blue,
            },
            '&:-ms-input-placeholder': {
                opacity: 1,
                color: theme.palette.common.blue,
            },
            '&::-ms-input-placeholder': {
                opacity: 1,
                color: theme.palette.common.blue,
            },
            '&::placeholder': {
                opacity: 1,
                color: theme.palette.common.blue,
            },
        },
    },
    MuiExpansionPanel: {
        root: {
            margin: '15px 0',
            border: 0,
            borderRadius: 6,
            background: theme.palette.common.lightGray,
            boxShadow: 'none',
            '&:first-child': {
                marginTop: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
            },
            '&:last-child': {
                marginBottom: 0,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
            },
            '&:before': {
                display: 'none',
            },
        },
        expanded: {
            margin: '15px 0',
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    MuiExpansionPanelSummary: {
        root: {
            padding: '0 25px 0 25px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 15px',
            },
        },
        content: {
            margin: '15px 0',
        },
        expandIcon: {
            color: theme.palette.primary.main,
        },
    },
    MuiExpansionPanelDetails: {
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: '8px 15px 15px',
            },
        },
    },
    MuiDialog: {
        paper: {
            [theme.breakpoints.down('sm')]: {
                margin: 5,
            },
        },
    },
    MuiDialogTitle: {
        root: {
            backgroundColor: theme.palette.common.purple,
        },
    },
    MuiDialogContent: {
        root: {
            padding: theme.gutters.sm,
            [theme.breakpoints.down('sm')]: {
                padding: theme.gutters.xs,
            },
        },
    },
    MuiDialogActions: {
        root: {
            margin: 0,
            padding: '0 20px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: theme.gutters.xs,
            },
        },
    },
    MuiMenuItem: {
        root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
    },
    MuiTab: {
        root: {
            fontFamily: theme.typography.fontFamily,
        },
    },
    MuiTableRow: {
        hover: {
            cursor: 'pointer',
        },
    },
    MuiTableCell: {
        head: {
            textTransform: 'uppercase',
            fontSize: 16,
            color: theme.palette.common.purple,
            borderColor: theme.palette.common.darkestBlue,
        },
        body: {
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 18,
            fontWeight: 'normal',
            color: theme.palette.common.white,
            borderColor: theme.palette.common.darkestBlue,
        },
    },
    MuiTableBody: {
        root: {
            '& tr:last-child': {
                '& th, & td': {
                    borderBottom: 0,
                },
            },
        },
    },
};

export default theme;
