import React from 'react';
import useStyles from './Main.styles';
import classNames from 'classnames';
import {canUseWebP} from 'react-img-webp';

export default function Main({theme, children}) {
    let isWebpSupported = canUseWebP();
    let classes = useStyles({isWebpSupported});

    return (
        <main className={classNames(classes['root'], classes[`root--${theme}`])}>
            <div className={classes['__holder']}>
                {children}
            </div>
            <div className={classNames(classes['__background'], classes[`__background--${theme}`])}>&nbsp;</div>
        </main>
    );
}
