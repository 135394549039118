import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: 'auto',
        },
    }),
    {
        name: 'MainMenu',
    },
);
