import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

import BgStars375 from 'images/stars-375.jpg';
import BgStars750 from 'images/stars-750.jpg';
import BgStars768 from 'images/stars-768.jpg';
import BgStars1366 from 'images/stars-1366.jpg';
import BgStars1536 from 'images/stars-1536.jpg';
import BgStars1920 from 'images/stars-1920.jpg';
import BgStars2732 from 'images/stars-2732.jpg';
import BgStars3840 from 'images/stars-3840.jpg';

import BgStars375Webp from 'images/stars-375.webp';
import BgStars750Webp from 'images/stars-750.webp';
import BgStars768Webp from 'images/stars-768.webp';
import BgStars1366Webp from 'images/stars-1366.webp';
import BgStars1536Webp from 'images/stars-1536.webp';
import BgStars1920Webp from 'images/stars-1920.webp';
import BgStars2732Webp from 'images/stars-2732.webp';
import BgStars3840Webp from 'images/stars-3840.webp';

import BgStarsLess375 from 'images/stars-less-375.jpg';
import BgStarsLess750 from 'images/stars-less-750.jpg';
import BgStarsLess768 from 'images/stars-less-768.jpg';
import BgStarsLess1366 from 'images/stars-less-1366.jpg';
import BgStarsLess1536 from 'images/stars-less-1536.jpg';
import BgStarsLess1920 from 'images/stars-less-1920.jpg';
import BgStarsLess2732 from 'images/stars-less-2732.jpg';
import BgStarsLess3840 from 'images/stars-less-3840.jpg';

import BgStarsLess375Webp from 'images/stars-less-375.webp';
import BgStarsLess750Webp from 'images/stars-less-750.webp';
import BgStarsLess768Webp from 'images/stars-less-768.webp';
import BgStarsLess1366Webp from 'images/stars-less-1366.webp';
import BgStarsLess1536Webp from 'images/stars-less-1536.webp';
import BgStarsLess1920Webp from 'images/stars-less-1920.webp';
import BgStarsLess2732Webp from 'images/stars-less-2732.webp';
import BgStarsLess3840Webp from 'images/stars-less-3840.webp';

import BgMountain from 'images/mountain.svg';

export default makeStyles((theme) => ({
        'root': merge({
                paddingTop: 250,
                paddingBottom: 250,
                position: 'relative',
                minHeight: 'calc(100vh - 123px)',
                '&:after': {
                    position: 'absolute',
                    bottom: -5,
                    left: '50%',
                    zIndex: 7,
                    content: '""',
                    display: 'block',
                    width: '100vw',
                    height: 200,
                    background: `url(${BgMountain}) no-repeat center bottom`,
                    backgroundSize: '100% auto',
                    transform: 'translateX(-50%)',
                    [theme.breakpoints.down('lg')]: {
                        height: 100,
                    },
                    [theme.breakpoints.down('md')]: {
                        height: 50,
                    },
                },
                [theme.breakpoints.down('lg')]: {
                    paddingBottom: 150,
                },
                [theme.breakpoints.down('md')]: {
                    paddingTop: 175,
                    paddingBottom: 100,
                },
                [theme.breakpoints.down('sm')]: {
                    paddingTop: 100,
                },
            },
            theme.gutter.md.padding.right,
            theme.gutter.md.padding.left,
            theme.typography.body1,
            {
                [theme.breakpoints.up(1600)]: {
                    paddingRight: [`calc((100% - ${theme.widthBase}px) / 2)`, '!important'],
                    paddingLeft: [`calc((100% - ${theme.widthBase}px) / 2)`, '!important'],
                },
                [theme.breakpoints.down('sm')]: {
                    paddingRight: ['0', '!important'],
                    paddingLeft: ['0', '!important'],
                },
            },
        ),
        'root--no-background': {
            paddingTop: 0,
            paddingRight: [0, '!important'],
            paddingLeft: [0, '!important'],
        },
        '__holder': {
            position: 'relative',
            zIndex: 5,
        },
        '__background': ({isWebpSupported}) => {
            let background = {
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1,
                width: '100%',
                height: '100%',
                background: `${theme.palette.background.default} no-repeat right bottom`,
                backgroundSize: 'cover',
                backgroundImage: `url(${BgStars375Webp})`,
                '@media (min-width: 375px) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 375px) and (max-width: 767px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStars750Webp})`,
                },
                [theme.breakpoints.between(768, 1365)]: {
                    backgroundImage: `url(${BgStars768Webp})`,
                },
                '@media (min-width: 768px) and (max-width: 1365px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (max-width: 1365px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStars1536Webp})`,
                },
                [theme.breakpoints.between(1366, 1919)]: {
                    backgroundImage: `url(${BgStars1366Webp})`,
                },
                '@media (min-width: 1366px) and (max-width: 1919px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1366px) and (max-width: 1919px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStars2732Webp})`,
                },
                [theme.breakpoints.up(1920)]: {
                    backgroundImage: `url(${BgStars1920Webp})`,
                },
                '@media (min-width: 1920px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1920px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStars3840Webp})`,
                },
            };

            if (!isWebpSupported) {
                background.backgroundImage = `url(${BgStars375})`;
                background['@media (min-width: 375px) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 375px) and (max-width: 767px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStars750})`,
                };
                background[theme.breakpoints.between(768, 1365)] = {
                    backgroundImage: `url(${BgStars768})`,
                };
                background['@media (min-width: 768px) and (max-width: 1365px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (max-width: 1365px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStars1536})`,
                };
                background[theme.breakpoints.between(1366, 1919)] = {
                    backgroundImage: `url(${BgStars1366})`,
                };
                background['@media (min-width: 1366px) and (max-width: 1919px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1366px) and (max-width: 1919px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStars2732})`,
                };
                background[theme.breakpoints.up(1920)] = {
                    backgroundImage: `url(${BgStars1920})`,
                };
                background['@media (min-width: 1920px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1920px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStars3840})`,
                };
            }

            return background;
        },
        '__background--no-background': ({isWebpSupported}) => {
            let background = {
                backgroundImage: `url(${BgStarsLess375Webp})`,
                '@media (min-width: 375px) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 375px) and (max-width: 767px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStarsLess750Webp})`,
                },
                [theme.breakpoints.between(768, 1365)]: {
                    backgroundImage: `url(${BgStarsLess768Webp})`,
                },
                '@media (min-width: 768px) and (max-width: 1365px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (max-width: 1365px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStarsLess1536Webp})`,
                },
                [theme.breakpoints.between(1366, 1919)]: {
                    backgroundImage: `url(${BgStarsLess1366Webp})`,
                },
                '@media (min-width: 1366px) and (max-width: 1919px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1366px) and (max-width: 1919px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStarsLess2732Webp})`,
                },
                [theme.breakpoints.up(1920)]: {
                    backgroundImage: `url(${BgStarsLess1920Webp})`,
                },
                '@media (min-width: 1920px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1920px) and (min-resolution: 192dpi)': {
                    backgroundImage: `url(${BgStarsLess3840Webp})`,
                },
            };

            if (!isWebpSupported) {
                background.backgroundImage = `url(${BgStarsLess375})`;
                background['@media (min-width: 375px) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 375px) and (max-width: 767px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStarsLess750})`,
                };
                background[theme.breakpoints.between(768, 1365)] = {
                    backgroundImage: `url(${BgStarsLess768})`,
                };
                background['@media (min-width: 768px) and (max-width: 1365px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (max-width: 1365px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStarsLess1536})`,
                };
                background[theme.breakpoints.between(1366, 1919)] = {
                    backgroundImage: `url(${BgStarsLess1366})`,
                };
                background['@media (min-width: 1366px) and (max-width: 1919px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1366px) and (max-width: 1919px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStarsLess2732})`,
                };
                background[theme.breakpoints.up(1920)] = {
                    backgroundImage: `url(${BgStarsLess1920})`,
                };
                background['@media (min-width: 1920px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1920px) and (min-resolution: 192dpi)'] = {
                    backgroundImage: `url(${BgStarsLess3840})`,
                };
            }

            return background;
        },
    }),
    {
        name: 'Main',
    },
);
