import React from 'react';
import useStyles from './QuickLinks.styles';
import {Typography} from '@material-ui/core';
import {Link} from 'react-navi';
import {Phone} from 'mdi-material-ui';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocale} from 'context';

export default function () {
    let classes = useStyles();
    let intl = useIntl();
    let {locale} = useLocale();
    let rating = 4.9;
    let supportLinks = [
        {
            label: <FormattedMessage
                id="components.Footer.QuickLinks.general"
                defaultMessage={`General`}
            />,
            url: intl.formatMessage({
                id: 'url.support.general',
                defaultMessage: '/support/general/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Footer.QuickLinks.order"
                defaultMessage={`Order`}
            />,
            url: intl.formatMessage({
                id: 'url.support.order',
                defaultMessage: '/support/order/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Footer.QuickLinks.payment"
                defaultMessage={`Payment`}
            />,
            url: intl.formatMessage({
                id: 'url.support.payment',
                defaultMessage: '/support/payment/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Footer.QuickLinks.technical"
                defaultMessage={`Technical`}
            />,
            url: intl.formatMessage({
                id: 'url.support.technical',
                defaultMessage: '/support/technical/',
            }),
        },
        {
            label: <FormattedMessage
                id="components.Footer.QuickLinks.serviceWarranty"
                defaultMessage={`Service & Warranty`}
            />,
            url: intl.formatMessage({
                id: 'url.support.serviceWarranty',
                defaultMessage: '/support/service-warranty/',
            }),
        },
    ];
    let landingPages = {
        'en': [
            {
                title: {
                    label: 'PSD Slicing',
                    url: '/psd-slicing/',
                },
                links: [
                    {
                        label: 'PSD to HTML',
                        url: '/psd-slicing/psd-to-html/',
                    },
                    {
                        label: 'PSD to WordPress',
                        url: '/psd-slicing/psd-to-wordpress/',
                    },
                    {
                        label: 'PSD to Email',
                        url: '/psd-slicing/psd-to-email/',
                    },
                    {
                        label: 'PSD to Responsive',
                        url: '/psd-slicing/psd-to-responsive/',
                    },
                ],
            },
            {
                title: {
                    label: 'Direct Basing®',
                    url: '/about-us/',
                },
                links: [
                    {
                        label: 'Pricing',
                        url: '/pricing/',
                    },
                    {
                        label: 'How it works',
                        url: '/how-it-works/',
                    },
                    {
                        label: 'About us',
                        url: '/about-us/',
                    },
                    {
                        label: 'Contact',
                        url: '/contact/',
                    },
                    {
                        label: 'Client Area',
                        url: '/user/',
                    },
                ],
            },
        ],
        'nl': [
            {
                title: {
                    label: 'Slicing',
                    url: '/slicing/',
                },
                links: [
                    {
                        label: 'PSD to HTML',
                        url: '/slicing/photoshop-psd-to-html-css/',
                    },
                    {
                        label: 'Sketch to HTML',
                        url: '/slicing/sketch-to-html/',
                    },
                    {
                        label: 'Adobe Xd to HTML',
                        url: '/slicing/adobe-xd-to-html/',
                    },
                    {
                        label: 'Figma to HTML',
                        url: '/slicing/figma-to-html/',
                    },
                    {
                        label: 'PSD to Bootstrap',
                        url: '/slicing/photoshop-psd-to-html-css/psd-to-bootstrap/',
                    },
                ],
            },
            {
                title: {
                    label: 'Direct Basing®',
                    url: '/over-ons/',
                },
                links: [
                    {
                        label: 'Tarieven',
                        url: '/tarieven/',
                    },
                    {
                        label: 'Werkwijze',
                        url: '/werkwijze/',
                    },
                    {
                        label: 'Over ons',
                        url: '/over-ons/',
                    },
                    {
                        label: 'Contact',
                        url: '/contact/',
                    },
                    {
                        label: 'Klantenpaneel',
                        url: '/user/',
                    },
                ],
            },
        ],
    };

    return (
        <div className={classes['root']}>
            <div className={classes['__column']}>
                <Typography
                    className={classes['__title']}
                    variant="h4"
                    component={Link}
                    href={intl.formatMessage({
                        id: 'url.support',
                        defaultMessage: '/support/',
                    })}
                >
                    <FormattedMessage
                        id="components.Footer.QuickLinks.support"
                        defaultMessage={`Support`}
                    />
                </Typography>
                <ul className={classes['__links']}>
                    {supportLinks.map((link, index) => (
                        <li key={index}><Link href={link.url}>{link.label}</Link></li>
                    ))}
                </ul>
            </div>
            {landingPages[locale].map((group, index) => (
                <div
                    key={index}
                    className={classes['__column']}
                >
                    <Typography
                        className={classes['__title']}
                        variant="h4"
                        component={Link}
                        href={group.title.url}
                    >
                        {group.title.label}
                    </Typography>
                    <ul className={classes['__links']}>
                        {group.links.map((link, lindex) => (
                            <li key={lindex}><Link href={link.url}>{link.label}</Link></li>
                        ))}
                    </ul>
                </div>
            ))}
            <div className={classes['__column']}>
                <Typography
                    className={classes['__title']}
                    variant="h4"
                    component="div"
                >
                    <FormattedMessage
                        id="components.Footer.QuickLinks.appreciation"
                        defaultMessage={`Appreciation`}
                    />
                </Typography>
                <Typography
                    className={classes['__rating-text']}
                    variant="body2"
                    component="div"
                >
                    <FormattedMessage
                        id="components.Footer.QuickLinks.appreciationText"
                        defaultMessage={`Customers appreciate our commitment on Google with a:`}
                    />
                </Typography>
                <div className={classes['__rating-stars']}>
                    <div
                        className={classes['__rating-stars-filled']}
                        style={{
                            width: `${rating * 20}%`,
                        }}
                    />
                    <span className={classes['__rating-label']}>{(rating * 2).toString().replace('.', ',')}</span>
                </div>
                <Typography
                    className={classes['__phone-text']}
                    variant="body2"
                    component="div"
                >
                    <FormattedMessage
                        id="components.Footer.QuickLinks.callUs"
                        defaultMessage={`Questions? Call us:`}
                    />
                    <br />
                    <Link
                        className={classes['__phone']}
                        href="tel://+31180700247"
                    >
                        <Phone />
                        +31 (0) 180 70 02 47
                    </Link>
                </Typography>
            </div>
        </div>
    );
};
