import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';
import StarImage from 'images/bg-star.svg';
import StarFilledImage from 'images/bg-star-filled.svg';

export default makeStyles((theme) => ({
        'root': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        '__column': merge({
                width: '25%',
                [theme.breakpoints.down('lg')]: {
                    width: '50%',
                    '&:nth-child(1)': {
                        order: 1,
                    },
                    '&:nth-child(2)': {
                        order: 3,
                    },
                    '&:nth-child(3)': {
                        order: 4,
                    },
                    '&:nth-child(4)': {
                        order: 2,
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    width: ['100%', '!important'],
                    '&:nth-child(1)': {
                        display: 'none',
                        order: [2, '!important'],
                    },
                    '&:nth-child(4)': {
                        order: [1, '!important'],
                    },
                },
                [theme.breakpoints.down(450)]: {
                    '&:nth-child(1)': {
                        display: 'none',
                    },
                    '&:nth-child(2)': {
                        width: ['auto', '!important'],
                    },
                    '&:nth-child(3)': {
                        width: ['auto', '!important'],
                    },
                },
            },
            theme.gutter.lg.margin.bottom,
        ),
        '__title': merge({
                display: 'block',
                textDecoration: 'none',
                color: theme.palette.common.white,
            },
            theme.gutter.sm.margin.bottom,
        ),
        '__links': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            '& li': {
                marginBottom: 5,
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
                [theme.breakpoints.up('md')]: {
                    '&:hover': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
        '__info': theme.gutter.sm.margin.bottom,
        '__rating-title': {
            color: theme.palette.common.white,
        },
        '__rating-text': {
            paddingBottom: 10,
        },
        '__rating-stars': {
            position: 'relative',
            width: 200,
            height: 40,
            backgroundRepeat: 'repeat-x',
            backgroundSize: '40px 40px',
            backgroundImage: `url(${StarImage})`,
        },
        '__rating-stars-filled': {
            width: 0,
            height: 40,
            backgroundRepeat: 'repeat-x',
            backgroundSize: '40px 40px',
            backgroundImage: `url(${StarFilledImage})`,
        },
        '__rating-label': {
            padding: '5px 7.5px',
            position: 'absolute',
            top: '50%',
            right: -65,
            fontSize: 28,
            lineHeight: 1,
            fontWeight: 'bold',
            color: theme.palette.common.white,
            borderRadius: 5,
            background: theme.palette.primary.main,
            transform: 'translateY(-50%)',
        },
        '__phone-text': {
            marginTop: 20,
        },
        '__phone': {
            marginTop: 5,
            paddingLeft: 35,
            position: 'relative',
            display: 'inline-block',
            textDecoration: 'none',
            color: 'inherit',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },
            '& svg': {
                position: 'absolute',
                top: '50%',
                left: 0,
                width: 30,
                height: 30,
                transform: 'translateY(-50%)',
            },
        },
    }),
    {
        name: 'QuickLinks',
    },
);
