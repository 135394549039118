import React from 'react';
import useStyles from './LoadingBar.styles';

export default function ({loading}) {
    let classes = useStyles();

    if (!loading) {
        return null;
    }

    return (
        <div className={classes['root']} />
    );
}
