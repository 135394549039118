import {FormattedMessage} from 'react-intl';
import React from 'react';
import {isEmpty} from 'lodash';
import scrollIntoView from 'scroll-into-view';

const getFields = (fields = {}, values = {}) => {
    if (values.projectType && fields.details) {
        let orderFields = {};

        if (fields[values.projectType].markup) {
            orderFields = Object.assign(orderFields, fields[values.projectType].markup);
        }

        if (fields[values.projectType].interactivity) {
            orderFields = Object.assign(orderFields, fields[values.projectType].interactivity);
        }

        if (fields.files) {
            orderFields = Object.assign(orderFields, fields.files);
        }

        if (fields.details) {
            orderFields = Object.assign(orderFields, fields.details);
        }

        fields = orderFields;
    }

    return fields;
};

const getRequiredFields = (fields = {}) => {
    let required = {};

    Object.keys(fields).map((key) => {
        if (fields[key].required) {
            required[key] = true;
        }

        return required;
    });

    return required;
};

const validateEmailAddress = (email) => {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
};

const validateFields = (inputFields = {}, values = {}, timesSubmitted = 0) => {
    let fields = getFields(inputFields, values);
    let required = getRequiredFields(fields);
    let errors = {};

    // If form is submitted before also check required values
    if (timesSubmitted > 0) {
        Object.keys(required).map((key) => {
            if (required[key] && !fields[key].value && !values[key]) {
                errors[key] = <FormattedMessage
                    id="helpers.form.errors.required"
                    defaultMessage={`This is a required field`}
                />;
            }

            return errors;
        });
    }

    Object.keys(fields).map((key) => {
        if (fields[key].type && fields[key].type === 'email') {
            if (!fields[key].value && !validateEmailAddress(values[key])) {
                errors[key] = <FormattedMessage
                    id="helpers.form.errors.emailAddressNotValid"
                    defaultMessage={`This is not a valid email address`}
                />;
            }
        }

        return errors;
    });

    if (values.password && values.confirmation && values.password !== values.confirmation) {
        errors.confirmation = <FormattedMessage
            id="helpers.form.errors.passwordsDontMatch"
            defaultMessage={`Passwords don't match`}
        />;
    }

    if (timesSubmitted > 0 && !isEmpty(errors)) {
        let selectors = Object.keys(errors);
        let selectedSelector = selectors[0];

        if (selectors.indexOf('reference') !== -1) {
            selectedSelector = 'reference';
        }

        scrollIntoView(document.querySelector(`#${selectedSelector}`), {
            time: 500,
        });
    }

    return errors;
};

const includeDefaultValues = (inputFields = {}, values = {}) => {
    let fields = getFields(inputFields, values);
    let allValues = {};

    Object.keys(fields).map((key) => {
        if (fields[key].defaultValue) {
            allValues[key] = fields[key].defaultValue;
        }

        return allValues;
    });

    Object.keys(values).map((key) => {
        allValues[key] = values[key];

        return allValues;
    });

    return allValues;
};

const getFieldName = (event, inputName) => {
    let fieldName = inputName;

    if (event !== false) {
        if (event.target.id) {
            fieldName = event.target.id;
        } else if (event.target.name) {
            fieldName = event.target.name;
        }
    }

    return fieldName;
};

const getFieldValue = (event, inputValue) => {
    if (inputValue !== false) {
        return inputValue;
    } else if (event !== false) {
        return event.target.value;
    } else {
        return '';
    }
};

const getAllValues = (event, inputName, inputValue, fields, values) => {
    let fieldName = getFieldName(event, inputName);

    if (event.target && event.target.type === 'checkbox') {
        if (!values[fieldName]) {
            values[fieldName] = [];
        }

        if (event.target.checked) {
            values[fieldName].push(getFieldValue(event, inputValue));
        } else {
            let index = values[fieldName].indexOf(getFieldValue(event, inputValue));

            if (index > -1) {
                values[fieldName].splice(index, 1);
            }
        }
    } else {
        values[fieldName] = getFieldValue(event, inputValue);
    }

    return includeDefaultValues(fields, values);
};

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    let k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export {
    getRequiredFields,
    validateFields,
    includeDefaultValues,
    getFieldName,
    getFieldValue,
    getAllValues,
    formatBytes,
};
