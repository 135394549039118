import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            paddingRight: 20,
            paddingLeft: 20,
            display: 'inline-block',
        },
        '--drawer': {
            paddingRight: 0,
            paddingLeft: 0,
            position: 'absolute',
            top: '50%',
            right: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
            transform: 'translateY(-50%)',
            '& button': {
                marginRight: 0,
                marginLeft: 0,
            },
        },
        '__button': {
            marginRight: -7,
            marginLeft: -7,
        },
        '__flag': {
            width: 20,
        },
    }),
    {
        name: 'LanguageToggle',
    },
);
