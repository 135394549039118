import {useCallback} from 'react';
import iplocation from 'iplocation';
import {firestore} from 'libs/firebase';

export default function useEvent() {
    let createEvent = useCallback(
        async (message) => {
            if (typeof window !== 'undefined') {
                try {
                    let appState = window.__APP_STATE__;
                    let countryCode = 'US';
                    let location = await iplocation(appState.currentUser.ip);

                    if (location.countryCode) {
                        countryCode = location.countryCode;
                    }

                    firestore.collection(`events`).add({
                        code: countryCode,
                        message: message,
                        timestamp: (new Date()),
                    });
                } catch (error) {
                    // No need for hard error here
                }
            }
        }, [],
    );

    return {
        createEvent,
    };
};
