import React from 'react';
import {IconButton} from '@material-ui/core';
import useStyles from './LanguageToggle.styles';
import classNames from 'classnames';
import FlagEn from 'images/flags/us.svg';
import FlagNl from 'images/flags/nl.svg';

export default function LanguageToggle({drawer}) {
    let classes = useStyles();

    return (
        <div className={(drawer) ? classNames(classes['root'], classes['--drawer']) : classes['root']}>
            <IconButton
                className={classes['__button']}
                aria-label="English"
                onClick={() => {
                    window.location.href = 'https://www.directbasing.com' + window.location.pathname;
                }}
            >
                <img
                    className={classes['__flag']}
                    src={FlagEn}
                    alt="English"
                />
            </IconButton>
            <IconButton
                className={classes['__button']}
                aria-label="Nederlands"
                onClick={() => {
                    window.location.href = 'https://www.directbasing.nl' + window.location.pathname;
                }}
            >
                <img
                    className={classes['__flag']}
                    src={FlagNl}
                    alt="Nederlands"
                />
            </IconButton>
        </div>
    );
}
