import React, {useState} from 'react';
import classNames from 'classnames';
import {Avatar, Button, Menu, MenuItem} from '@material-ui/core';
import {Link, useCurrentRoute, useNavigation} from 'react-navi';
import {FormattedMessage} from 'react-intl';
import {auth} from 'libs/firebase';
import useStyles from './UserMenu.styles';
import {useCurrentUser} from 'context';
import Gravatar from 'react-gravatar';

export default function UserMenu() {
    let classes = useStyles();
    let route = useCurrentRoute();
    let navigation = useNavigation();
    let {currentUser} = useCurrentUser();
    let [menuElement, setMenuElement] = useState(null);
    let [menuOpen, setMenuOpen] = useState(false);

    return (
        <span className={classes['root']}>
            <Button
                className={classNames(classes['__button'], classes['--avatar'])}
                variant="contained"
                size="small"
                onClick={(event) => {
                    if (!menuOpen) {
                        setMenuElement(event.target);
                        setMenuOpen(true);
                    }
                }}
            >
                {currentUser.photoURL ? (
                    <Avatar
                        className={classes['__icon']}
                        src={currentUser.photoURL}
                        alt={currentUser.displayName}
                    />
                ) : (
                    <Gravatar
                        className={classes['__gravatar']}
                        email={currentUser.email}
                        size={40}
                        rating="pg"
                        default="mp"
                    />
                )}
                <FormattedMessage
                    id="components.Header.UserMenu.clientArea"
                    defaultMessage={`Client Area`}
                />
            </Button>
            <Menu
                anchorEl={menuElement}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                PaperProps={{
                    style: {
                        minWidth: 175,
                    },
                }}
            >
                <MenuItem
                    selected={route.url.pathname === '/user/'}
                    component={Link}
                    href="/user/"
                    onClick={() => setMenuOpen(false)}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.profile"
                        defaultMessage={`Profile`}
                    />
                </MenuItem>
                <MenuItem
                    selected={route.url.pathname === '/user/quotes/'}
                    component={Link}
                    href="/user/quotes/"
                    onClick={() => setMenuOpen(false)}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.quotes"
                        defaultMessage={`Quotes`}
                    />
                </MenuItem>
                <MenuItem
                    selected={route.url.pathname === '/user/projects/'}
                    component={Link}
                    href="/user/projects/"
                    onClick={() => setMenuOpen(false)}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.projects"
                        defaultMessage={`Projects`}
                    />
                </MenuItem>
                <MenuItem
                    selected={route.url.pathname === '/user/invoices/'}
                    component={Link}
                    href="/user/invoices/"
                    onClick={() => setMenuOpen(false)}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.invoices"
                        defaultMessage={`Invoices`}
                    />
                </MenuItem>
                <MenuItem
                    selected={route.url.pathname === '/user/tickets/'}
                    component={Link}
                    href="/user/tickets/"
                    onClick={() => setMenuOpen(false)}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.tickets"
                        defaultMessage={`Tickets`}
                    />
                </MenuItem>
                <MenuItem
                    selected={route.url.pathname === '/logout/'}
                    onClick={async () => {
                        await auth.signOut();
                        await navigation.navigate('/');
                    }}
                >
                    <FormattedMessage
                        id="components.Header.UserMenu.logout"
                        defaultMessage={`Logout`}
                    />
                </MenuItem>
            </Menu>
        </span>
    );
}
