import React from 'react';

const CheckboxUnchecked = (props) => (
    <svg
        width="25px"
        height="25px"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g
            id="Order-now"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="order-now---form"
                transform="translate(-236.000000, -2728.000000)"
                fill="#FFFFFF"
                stroke="#9FA2BD"
            >
                <g
                    id="steps"
                    transform="translate(-148.000000, 433.000000)"
                >
                    <g
                        id="3.-upload"
                        transform="translate(0.000000, 2053.000000)"
                    >
                        <g
                            id="filetype"
                            transform="translate(333.000000, 64.000000)"
                        >
                            <g
                                id="checkbox"
                                transform="translate(51.000000, 159.000000)"
                            >
                                <rect
                                    x="0.5"
                                    y="19.5"
                                    width="24"
                                    height="24"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default CheckboxUnchecked;
