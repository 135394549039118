import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: '100%',
        },
        '__button': {
            display: 'none',
        },
    }),
    {
        name: 'LoginForm',
    },
);
