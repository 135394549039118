import {useCallback, useState} from 'react';

export default function useLocale(appLocale) {
    let [locale, setState] = useState(appLocale);

    let setLocale = useCallback(
        (language) => {
            setState(language);
        }, [],
    );

    return {
        locale,
        setLocale,
    };
};
