import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            color: theme.palette.common.white,
            backgroundColor: '#4984ef',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    backgroundColor: '#3971eb',
                },
            },
        },
        '__icon': {
            marginRight: 10,
        },
    }),
    {
        name: 'GoogleButton',
    },
);
