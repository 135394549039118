import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            width: '100%',
            height: 2,
            background: theme.palette.primary.main,
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2) inset',
            transition: 'transform ease-in 300ms, opacity ease-in 300ms',
            transitionDelay: '100ms',
            transformOrigin: 'left center',
            transform: 'scaleX(0)',
            animation: '$loadingBar 10s ease-out',
            animationFillMode: 'forwards',
        },
        '@keyframes loadingBar': {
            '0%': {
                transform: 'scaleX(0)',
            },
            '10%': {
                transform: 'scaleX(0.3)',
            },
            '50%': {
                transform: 'scaleX(0.7)',
            },
            '90%': {
                transform: 'scaleX(0.8)',
            },
            '100%': {
                transform: 'scaleX(1)',
            },
        },
    }),
    {
        name: 'LoadingBar',
    },
);
