import React from 'react';
import classNames from 'classnames';
import {isEmpty} from 'lodash';
import {Button, CircularProgress, Input, MenuItem, Select, TextField} from '@material-ui/core';
import Fieldset from './Fieldset';
import SelectGroupField from './SelectGroupField';
import CheckboxGroupField from './CheckboxGroupField';
import RadioGroupField from './RadioGroupField';
import UploadField from './UploadField';
import Autocomplete from './Autocomplete';
import useStyles from './Form.styles';
import {FormattedMessage} from 'react-intl';

export default function ({fullWidth = false, dark, fields, values, errors, changeHandler, validationHandler, submitHandler, loading, button, children}) {
    let classes = useStyles();
    let formClass = classes['form'];

    if (fullWidth) {
        if (dark) {
            formClass = classNames(classes['form'], classes['form--full-width'], classes['form--dark']);
        } else {
            formClass = classNames(classes['form'], classes['form--full-width']);
        }
    } else if (dark) {
        formClass = classNames(classes['form'], classes['form--dark']);
    }

    return (
        <form
            className={classes['root']}
            onSubmit={submitHandler}
            noValidate
        >
            <div className={formClass}>
                {(errors.form) ? (
                    <div className={classes['__error']}>
                        {errors.form}
                    </div>
                ) : null}
                <div className={classes['__fieldset']}>
                    {Object.keys(fields).map((value) => {
                        let field = null;
                        let hasError = false;
                        let hasHelperText = null;

                        if (fields[value].helper) {
                            hasHelperText = fields[value].helper;
                        }

                        if (errors[value]) {
                            hasError = true;
                            hasHelperText = errors[value];
                        }

                        if (fields[value].dependencies) {
                            let dependenciesMet = true;
                            fields[value].dependencies.forEach((dependecy) => {
                                if (dependenciesMet && values[dependecy.name] && values[dependecy.name].toString() !== dependecy.value.toString()) {
                                    dependenciesMet = false;
                                } else if (dependenciesMet && !values[dependecy.name] && fields[dependecy.name].defaultValue.toString() !== dependecy.value.toString()) {
                                    dependenciesMet = false;
                                }
                            });

                            if (!dependenciesMet) {
                                return null;
                            }
                        }

                        if (fields[value].type === 'radioGroup') {
                            field = <RadioGroupField
                                name={value}
                                value={(!isEmpty(values[value])) ? values[value] : fields[value].defaultValue}
                                values={fields[value].values}
                                required={fields[value].required}
                                changeHandler={changeHandler}
                                validationHandler={validationHandler}
                            />;
                        } else if (fields[value].type === 'checkboxGroup') {
                            field = <CheckboxGroupField
                                name={value}
                                value={(!isEmpty(values[value])) ? values[value] : fields[value].defaultValue}
                                values={fields[value].values}
                                required={fields[value].required}
                                changeHandler={changeHandler}
                                validationHandler={validationHandler}
                            />;
                        } else if (fields[value].type === 'selectGroup') {
                            field = <SelectGroupField
                                name={value}
                                value={(!isEmpty(values[value])) ? values[value] : fields[value].defaultValue}
                                values={fields[value].values}
                                required={fields[value].required}
                                changeHandler={changeHandler}
                                validationHandler={validationHandler}
                            />;
                        } else if (fields[value].type === 'autocomplete') {
                            field = <Autocomplete
                                name={value}
                                value={(!isEmpty(values[value])) ? values[value] : fields[value].defaultValue}
                                values={fields[value].values}
                                required={fields[value].required}
                                fullWidth={fields[value].fullWidth}
                                changeHandler={changeHandler}
                                validationHandler={validationHandler}
                            />;
                        } else if (fields[value].type === 'upload') {
                            field = <UploadField
                                name={value}
                                value={(!isEmpty(values[value])) ? values[value] : fields[value].defaultValue}
                                values={fields[value].values}
                                required={fields[value].required}
                                config={fields[value].config}
                                uploadHandler={fields[value].uploadHandler}
                                removeHandler={fields[value].removeHandler}
                            />;
                        } else if (fields[value].type === 'select') {
                            let selectValue = (!isEmpty(values[value])) ? values[value] : fields[value].defaultValue;

                            if (!selectValue) {
                                selectValue = '';
                            }

                            return (
                                <Fieldset
                                    key={value}
                                    label={fields[value].label}
                                    className={(fields[value].fullWidth) ? classNames(classes['__select'], classes['__select--full-width']) : classes['__select']}
                                    description={fields[value].description}
                                    helper={fields[value].helper}
                                    hasError={hasError}
                                    fullWidth={fields[value].fullWidth}
                                >
                                    <Select
                                        key={value}
                                        id={value}
                                        classes={{
                                            root: classes['__select-root'],
                                            select: classes['__select-input'],
                                        }}
                                        onChange={changeHandler}
                                        onBlur={validationHandler}
                                        value={selectValue}
                                        required={fields[value].required}
                                        input={<Input
                                            name={value}
                                            disableUnderline={true}
                                        />}
                                    >
                                        {Object.keys(fields[value].values).map((selectValue) => (
                                            <MenuItem
                                                key={selectValue}
                                                value={selectValue}
                                            >
                                                {fields[value].values[selectValue]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Fieldset>
                            );
                        } else {
                            let inputValue = (!isEmpty(values[value])) ? values[value] : fields[value].defaultValue;

                            if (!inputValue) {
                                inputValue = '';
                            }

                            return (
                                <TextField
                                    key={value}
                                    id={value}
                                    className={(fields[value].fullWidth) ? classNames(classes['__textfield'], classes['__textfield--full-width']) : classes['__textfield']}
                                    onChange={changeHandler}
                                    onBlur={validationHandler}
                                    value={inputValue}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: (fields[value].multiline) ? classes['__textarea'] : classes['__input'],
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        className: (dark) ? classNames(classes['__input-label'], classes['__input-label--dark']) : classes['__input-label'],
                                    }}
                                    error={hasError}
                                    helperText={hasHelperText}
                                    {...fields[value]}
                                />
                            );
                        }

                        return (
                            <Fieldset
                                key={value}
                                label={fields[value].label}
                                required={fields[value].required}
                                description={fields[value].description}
                                helper={fields[value].helper}
                                hasError={hasError}
                                fullWidth={fields[value].fullWidth}
                                noBottom={fields[value].noBottom}
                            >
                                {field}
                            </Fieldset>
                        );
                    })}
                </div>
            </div>
            {children}

            {(button) ? (
                <Button
                    id={(button.id) ? button.id : null}
                    className={(button.class) ? button.class : classes['__button']}
                    variant="contained"
                    color={(button.color) ? button.color : 'secondary'}
                    type="submit"
                    disabled={loading}
                >
                    {(loading) ? (
                        <CircularProgress size={28} />
                    ) : (
                        <>
                            {(button.label) ? button.label : (
                                <FormattedMessage
                                    id="components.Form.submit"
                                    defaultMessage={`Submit`}
                                />
                            )}
                        </>
                    )}
                </Button>
            ) : null}
        </form>
    );
}
