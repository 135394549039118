import React from 'react';
import Autosuggest from 'react-autosuggest';
import {MenuItem, Paper, TextField} from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import useStyles from './Autocomplete.styles';
import deburr from 'lodash/deburr';

const getSuggestions = (suggestions, value) => {
    let inputValue = deburr(value.trim()).toLowerCase();
    let inputLength = inputValue.length;
    let count = 0;

    if (inputLength === 0) {
        return [];
    } else {
        return suggestions.filter((suggestion) => {
            const keep = count < 5 && suggestion.label.indexOf(inputValue) !== -1;

            if (keep) {
                count += 1;
            }

            return keep;
        });
    }
};

const renderInputComponent = (inputProps) => {
    let {
        classes, inputRef = () => {
        }, ref, ...other
    } = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                disableUnderline: true,
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
};

const renderSuggestion = (suggestion, {query, isHighlighted}) => {
    let matches = match(suggestion.label, query);
    let parts = parse(suggestion.label, matches);

    return (
        <MenuItem
            selected={isHighlighted}
            component="div"
        >
            <div>
                {parts.map((part, index) =>
                    part.highlight ? (
                        <span
                            key={String(index)}
                            style={{fontWeight: 600}}
                        >
                            {part.text}
                        </span>
                    ) : (
                        <strong
                            key={String(index)}
                            style={{fontWeight: 300}}
                        >
                            {part.text}
                        </strong>
                    ),
                )}
            </div>
        </MenuItem>
    );
};

const getSuggestionValue = (suggestion) => {
    return suggestion.value;
};

export default function ({name, value = '', values, required, fullWidth, validationHandler, changeHandler}) {
    let classes = useStyles();
    let autosuggestProps = {
        renderInputComponent,
        suggestions: values,
        onSuggestionsFetchRequested: ({value}) => {
            return {
                suggestions: getSuggestions(values, value),
            };
        },
        onSuggestionsClearRequested: () => {
            return {
                suggestions: [],
            };
        },
        getSuggestionValue,
        renderSuggestion,
    };

    return (
        <Autosuggest
            {...autosuggestProps}
            inputProps={{
                id: name,
                classes: {
                    input: classes['__input'],
                },
                value: value,
                required: required,
                fullWidth: fullWidth,
                onChange: (event, {newValue}) => {
                    changeHandler(false, name, newValue);

                    return {
                        autocompleteValue: newValue,
                    };
                },
                onBlur: validationHandler,
            }}
            theme={{
                container: classes['__container'],
                suggestionsContainerOpen: classes['__container-open'],
                suggestionsList: classes['__suggestions-list'],
                suggestion: classes['__suggestion'],
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                    {options.children}
                </Paper>
            )}
        />
    );
}
