import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: '100%',
        },
        '__input': {
            marginTop: 20,
            padding: '0 10px',
            height: 50,
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius / 2,
            background: theme.palette.common.white,
        },
        '__container': {
            position: 'relative',
        },
        '__container-open': {
            position: 'absolute',
            zIndex: 1,
            marginTop: 10,
            left: 0,
            right: 0,
        },
        '__suggestion': {
            display: 'block',
        },
        '__suggestions-list': {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
    }),
    {
        name: 'Autocomplete',
    },
);
