import React from 'react';
import {Helmet} from 'react-helmet-async';

export default function () {
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(
                {
                    '@context': 'https://schema.org',
                    '@graph': [
                        {
                            '@type': 'Organization',
                            '@id': 'https://www.directbasing.nl/#organization',
                            'name': 'Direct Basing®',
                            'url': 'https://www.directbasing.nl/',
                            'sameAs': [
                                'https://www.directbasing.com/',
                                'https://g.page/DirectBasing/',
                            ],
                            'logo': {
                                '@type': 'ImageObject',
                                '@id': 'https://www.directbasing.nl/#logo',
                                'url': 'https://www.directbasing.nl/images/icons/icon-512x512.png',
                                'width': 512,
                                'height': 512,
                                'caption': 'Direct Basing®',
                            },
                            'image': {
                                '@id': 'https://www.directbasing.nl/#logo',
                            },
                            'aggregateRating': {
                                '@type': 'AggregateRating',
                                'ratingValue': '4.9',
                                'bestRating': '5',
                                'worstRating': '1',
                                'reviewCount': '13',
                            },
                        },
                        {
                            '@type': 'WebSite',
                            '@id': 'https://www.directbasing.nl/#website',
                            'url': 'https://www.directbasing.nl/',
                            'name': 'Direct Basing®',
                            'publisher': {
                                '@id': 'https://www.directbasing.nl/#organization',
                            },
                        },
                    ],
                },
            )}</script>
        </Helmet>
    );
}
