import React from 'react';
import classNames from 'classnames';
import {FormControlLabel, RadioGroup} from '@material-ui/core';
import Radio from './Radio';
import useStyles from './RadioGroupField.styles';

export default function ({name, value = '', values, required = false, changeHandler, validationHandler}) {
    let classes = useStyles();

    return (
        <RadioGroup
            className={classes['root']}
            name={name}
            onChange={changeHandler}
            onBlur={validationHandler}
            value={value}
            required={required}
        >
            {Object.keys(values).map((radioValue) => (
                <FormControlLabel
                    key={radioValue}
                    className={(value === radioValue) ? classNames(classes['__item'], classes['__item--active']) : classes['__item']}
                    classes={{
                        label:
                            value === radioValue
                                ? classNames(classes['__label'], classes['__label--active'])
                                : classes['__label'],
                    }}
                    value={radioValue}
                    control={<Radio id={name} />}
                    label={values[radioValue]}
                />
            ))}
        </RadioGroup>
    );
}
