import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1200,
            width: '100%',
            background: theme.palette.common.darkBlue,
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        '__menu': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.common.purple}`,
            background: [theme.palette.common.darkBlue, '!important'],
        },
        '__link': {
            color: theme.palette.common.purple,
            [theme.breakpoints.up(350)]: {
                '&:first-child': {
                    paddingLeft: 30,
                },
                '&:last-child': {
                    paddingRight: 30,
                },
            },
        },
        '__label': {
            fontSize: 13,
        },
        '__label-selected': {
            fontSize: [13, '!important'],
            color: [theme.palette.common.white, '!important'],
            '& svg': {
                fill: theme.palette.common.white,
            },
        },
        '__drawer': {
            width: '90vw',
            color: theme.palette.common.white,
            background: theme.palette.common.darkBlue,
        },
        '__logo': {
            position: 'relative',
            display: 'block',
            height: 80,
        },
        '__drawer-icon': {
            marginLeft: 5,
            marginRight: 10,
            position: 'relative',
            fontSize: 18,
            color: [theme.palette.common.purple, '!important'],
        },
        '__avatar': {
            position: 'absolute',
            top: -1,
            left: -7,
            width: 30,
            height: 30,
        },
        '__gravatar': {
            position: 'absolute',
            top: -20,
            left: -7,
            width: 40,
            height: 40,
            borderRadius: '50%',
        },
        '__drawer-label': {
            fontSize: 18,
            color: theme.palette.common.white,
        },
        '__drawer-submenu': {
            paddingTop: 15,
            paddingBottom: 15,
            background: theme.palette.common.darkerBlue,
        },
        '__drawer-subitem': {
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 70,
        },
        '__drawer-sublabel': {
            paddingLeft: 20,
            position: 'relative',
            display: 'block',
            fontSize: 17,
            color: theme.palette.common.white,
            '&:before': {
                position: 'absolute',
                top: '50%',
                left: 0,
                display: 'block',
                content: '"-"',
                transform: 'translateY(-50%)',
            },
        },
    }),
    {
        name: 'BottomMenu',
    },
);
