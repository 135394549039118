import React, {useEffect} from 'react';
import {getBreakpoints} from 'helpers/styles';
import useStyles from './MobileWrapper.styles';
import useWindowSize from 'hooks/useWindowSize';

export default function MobileWrapper({children}) {
    let classes = useStyles();
    let {windowSize} = useWindowSize();

    useEffect(() => {
        let bottomMenu = document.getElementById('bottomMenu');

        if (bottomMenu) {
            if (
                windowSize.standalone &&
                windowSize.devicePixelRatio === 3 &&
                ((windowSize.deviceWidth === 375 && windowSize.deviceHeight === 812) || (windowSize.deviceWidth === 812 && windowSize.deviceHeight === 375))
            ) {
                // iPhone X
                bottomMenu.style.paddingBottom = '25px';
            } else {
                bottomMenu.removeAttribute('style');
            }
        }

        let mobileWrapper = document.getElementById('mobileWrapper');
        let bottomMenuHeight = (bottomMenu !== null) ? bottomMenu.offsetHeight : 56;
        let mobileWrapperHeight = windowSize.height - bottomMenuHeight;

        if (windowSize.width < getBreakpoints.sm) {
            if (mobileWrapper) {
                let projectFrame = document.getElementById('projectFrame');

                mobileWrapper.style.overflow = 'auto';
                mobileWrapper.style.overflowX = 'hidden';

                if (projectFrame !== null) {
                    mobileWrapper.style.height = windowSize.height + 'px';
                } else {
                    mobileWrapper.style.height = mobileWrapperHeight + 'px';
                }
            }
        } else {
            if (mobileWrapper) {
                mobileWrapper.removeAttribute('style');
            }
        }
    }, [windowSize]);

    return (
        <div
            id="mobileWrapper"
            className={classes['root']}
        >
            {children}
        </div>
    );
}
