import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: 'auto',
        },
        '__button': {
            color: theme.palette.common.white,
            backgroundColor: 'transparent',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    backgroundColor: theme.palette.common.darkBlue,
                },
            },
        },
        '--avatar': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
        },
        '__icon': {
            marginRight: 10,
            maxWidth: 38,
            maxHeight: 38,
        },
        '__avatar': {
            marginRight: 10,
            maxWidth: 38,
            maxHeight: 38,
            color: theme.palette.common.white,
            background: theme.palette.common.blue,
        },
        '__gravatar': {
            marginRight: 10,
            maxWidth: 38,
            maxHeight: 38,
            borderRadius: '50%',
        },
    }),
    {
        name: 'UserMenu',
    },
);
