import React from 'react';
import {Link} from 'react-navi';
import LogoImage from 'images/logo.svg';
import {FormattedMessage} from 'react-intl';
import {Hidden} from '@material-ui/core';
import useStyles from './Logo.styles';

export default function ({sticky}) {
    let classes = useStyles({
        sticky: sticky,
    });

    return (
        <Link
            className={classes['root']}
            href="/"
        >
            <img
                className={classes['__logo']}
                src={LogoImage}
                alt="Direct Basing&reg;"
            />
            <strong className={classes['__name']}>Direct Basing&reg;</strong>
            <Hidden xsDown>
                <FormattedMessage
                    id="components.Logo.slogan"
                    defaultMessage={`Your design. Our code.`}
                />
            </Hidden>
        </Link>
    );
}
