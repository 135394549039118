import React, {useCallback, useState} from 'react';
import {getAllValues, validateFields} from 'helpers/form';
import {isEmpty} from 'lodash';
import {logError} from 'helpers/debug';
import {useSnackbar} from 'notistack';
import {FormattedMessage} from 'react-intl';
import {useNavigation} from 'react-navi';

export default function useForm(fields, submitCallback, successMessage, blurCallback) {
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState({});
    let [values, setValues] = useState({});
    let [timesSubmitted, setTimesSubmitted] = useState(0);
    let {enqueueSnackbar} = useSnackbar();
    let navigation = useNavigation();

    let handleOnChange = useCallback(
        async (event = false, inputName = false, inputValue = false) => {
            let allValues = getAllValues(event, inputName, inputValue, fields, values);

            setValues(allValues);

            if (!event && blurCallback) {
                await blurCallback(allValues, false);
            }
        },
        [fields, values, blurCallback],
    );

    let handleOnBlur = useCallback(
        async () => {
            let errors = validateFields(fields, values, timesSubmitted);

            setErrors(errors);

            if (blurCallback) {
                await blurCallback(values, errors);
            }
        },
        [fields, timesSubmitted, values, blurCallback],
    );

    let handleOnSubmit = useCallback(
        async (event) => {
            if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
            }

            let errors = validateFields(fields, values, 1);

            setTimesSubmitted(timesSubmitted + 1);

            if (isEmpty(errors)) {
                setLoading(true);

                try {
                    await submitCallback(values);

                    setTimesSubmitted(0);
                    setValues({});

                    if (successMessage) {
                        enqueueSnackbar(successMessage, {
                            variant: 'success',
                        });
                    }
                } catch (error) {
                    if (logError(error)) {
                        if (error.code === 'auth/invalid-email') {
                            error.message = <FormattedMessage
                                id="helpers.form.errors.emailAddressNotValid"
                                defaultMessage={error.message}
                            />;
                        } else if (error.code === 'auth/email-already-in-use') {
                            error.message = <FormattedMessage
                                id="helpers.form.errors.emailInUse"
                                defaultMessage={error.message}
                            />;
                        } else if (error.code === 'auth/weak-password') {
                            error.message = <FormattedMessage
                                id="helpers.form.errors.passwordWeak"
                                defaultMessage={error.message}
                            />;
                        } else if (error.code === 'auth/weak-password') {
                            error.message = <FormattedMessage
                                id="helpers.form.errors.passwordWeak"
                                defaultMessage={error.message}
                            />;
                        } else if (error.code === 'auth/wrong-password') {
                            error.message = <FormattedMessage
                                id="helpers.form.errors.wrongPassword"
                                defaultMessage={error.message}
                            />;
                        } else if (error.code === 'auth/user-token-expired') {
                            await navigation.navigate(`/`);
                        } else if (error.code === 'auth/requires-recent-login') {
                            document.getElementById('loginButtonHeader').click();
                        }

                        enqueueSnackbar(error.message, {
                            variant: 'error',
                        });
                    }
                }

                setLoading(false);
            } else {
                setErrors(errors);
            }
        },
        [fields, timesSubmitted, values, submitCallback, enqueueSnackbar, successMessage, navigation],
    );

    return {
        fields,
        loading,
        setLoading,
        values,
        setValues,
        errors,
        setErrors,
        handleOnChange,
        handleOnBlur,
        handleOnSubmit,
    };
}
