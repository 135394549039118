import {useCallback, useEffect, useState} from 'react';
import {auth, firestore} from 'libs/firebase';
import {getUserId} from 'helpers/user';
import {isEmpty, isEqual} from 'lodash';
import {useLocale} from 'context';

export default function useCurrentUser() {
    let [currentUser, setState] = useState({});
    let [authenticating, setAuthenticating] = useState(() => {
        let currentUser = auth.currentUser;

        return !currentUser;
    });
    let {locale} = useLocale();

    useEffect(() => {
        function onChange(user) {
            if (!user) {
                setState({});
                setAuthenticating(false);
            } else {
                let userId = getUserId(user.email);
                let lastSignInTime = new Date(user.metadata.lastSignInTime);

                setState({
                    defaultAddress: user.defaultAddress,
                    displayName: user.displayName,
                    email: user.email.toLowerCase(),
                    emailVerified: user.emailVerified,
                    firstName: user.firstName,
                    newsletter: user.newsletter,
                    language: user.language,
                    lastSignInTime: lastSignInTime,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL,
                    uid: user.uid,
                    userId: userId,
                });

                setAuthenticating(false);
            }
        }

        let unsubscribe = auth.onAuthStateChanged(onChange);

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        let mounted = true;

        async function getUser() {
            try {
                let user = await firestore.doc(`users/${currentUser.userId}`).get();
                let userData = user.data();
                let nameData = {};

                if (currentUser && (!userData.lastSignInTime)) {
                    await firestore.doc(`users/${currentUser.userId}`).update({
                        lastSignInTime: currentUser.lastSignInTime,
                    });
                }

                // When registered with Google button there is no data available right away, so we create it
                if (!userData) {
                    userData = currentUser;

                    if (!userData.defaultAddress) {
                        delete userData.defaultAddress;
                    }

                    if (!userData.firstName) {
                        delete userData.firstName;
                    }

                    if (!userData.language) {
                        userData.language = locale;
                    }

                    if (!userData.newsletter) {
                        userData.newsletter = true;
                    }

                    if (!userData.lastName) {
                        delete userData.lastName;
                    }

                    await firestore.doc(`users/${currentUser.userId}`).set(userData, {
                        merge: true,
                    });
                }

                if (userData.displayName) {
                    let fullName = userData.displayName.split(' ');

                    if (!userData.firstName) {
                        nameData.firstName = fullName[0];
                    }

                    if (!userData.lastName) {
                        if (nameData.firstName) {
                            nameData.lastName = userData.displayName.replace(nameData.firstName, '').trim();
                        } else {
                            nameData.lastName = userData.displayName;
                        }
                    }

                    userData = Object.assign(userData, nameData);
                }

                if (mounted && !isEqual(userData, currentUser)) {
                    setState(userData);
                }

                if (!isEmpty(nameData)) {
                    firestore.doc(`users/${currentUser.userId}`).update(userData);
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (!authenticating && currentUser.userId) {
            getUser();
        }

        return () => mounted = false;
    }, [authenticating, currentUser, locale]);

    let setCurrentUser = useCallback(
        (user) => {
            setState(Object.assign(currentUser, user));
        }, [currentUser],
    );

    return {
        authenticating,
        currentUser,
        setCurrentUser,
    };
};
