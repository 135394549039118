import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            marginRight: 40,
            marginLeft: 0,
            paddingRight: 0,
            paddingLeft: 0,
            opacity: 1,
            minWidth: 0,
            fontSize: 22,
            fontWeight: 400,
            color: theme.palette.common.white,
            '&:last-child': {
                marginRight: 0,
            },
            [theme.breakpoints.down('md')]: {
                marginRight: 20,
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: 10,
                fontSize: 18,
            },
            [theme.breakpoints.down(350)]: {
                marginRight: 7.5,
                fontSize: 16,
            },
        },
    }),
    {
        name: 'MenuLink',
    },
);
