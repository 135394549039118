import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Title from 'components/Title';
import {Button, Hidden} from '@material-ui/core';
import {Link} from 'react-navi';
import RocketImg from 'images/rocket.svg';
import useStyles from './NotFound.styles';
import {Helmet} from 'react-helmet-async';

export default function () {
    let classes = useStyles();
    let intl = useIntl();

    return (
        <div className={classes['root']}>
            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: 'meta.NotFound.title',
                    })}
                </title>
            </Helmet>
            <div className={classes['__content']}>
                <Title
                    title={
                        <FormattedMessage
                            id="components.NotFound.pageTitle"
                            defaultMessage={`Page not Found`}
                        />
                    }
                    subtitle={
                        <FormattedMessage
                            id="components.NotFound.pageSubtitle"
                            defaultMessage={`Mayday mayday, something went wrong!`}
                        />
                    }
                />
                <div className={classes['__buttons']}>
                    <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        href="/"
                    >
                        <FormattedMessage
                            id="components.NotFound.homepage"
                            defaultMessage={`Homepage`}
                        />
                    </Button>
                </div>
            </div>
            <Hidden xsDown>
                <div className={classes['__animation']}>
                    <img
                        className={classes['__rocket']}
                        src={RocketImg}
                        alt={<FormattedMessage
                            id="components.NotFound.crashedRocket"
                            defaultMessage={`Crashed Rocket`}
                        />}
                    />
                    <div className={classes['__smoke']}>
                        <span className={classes['__smoke-0']}>
                        </span>
                        <span className={classes['__smoke-1']}>
                        </span>
                        <span className={classes['__smoke-2']}>
                        </span>
                        <span className={classes['__smoke-3']}>
                        </span>
                        <span className={classes['__smoke-4']}>
                        </span>
                        <span className={classes['__smoke-5']}>
                        </span>
                        <span className={classes['__smoke-6']}>
                        </span>
                        <span className={classes['__smoke-7']}>
                        </span>
                        <span className={classes['__smoke-8']}>
                        </span>
                        <span className={classes['__smoke-9']}>
                        </span>
                    </div>
                </div>
            </Hidden>
        </div>
    );
}
