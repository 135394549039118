import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': merge({
                width: '100%',
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            theme.gutter.md.margin.bottom,
        ),
        '--no-bottom': {
            marginBottom: 20,
            '&:last-child': {
                marginBottom: 0,
            },
        },
        '__title': {
            fontWeight: 600,
            fontSize: 20,
            color: theme.palette.common.blue,
        },
        '__description': merge({
                marginTop: 10,
                display: 'block',
                lineHeight: 1.4,
                fontSize: 16,
                color: theme.palette.common.blue,
            },
            theme.gutter.sm.margin.bottom,
        ),
        '__helper': {
            paddingTop: 5,
        },
    }),
    {
        name: 'Fieldset',
    },
);
