import React, {useEffect, useState} from 'react';
import useStyles from './BottomMenu.styles';
import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {Link, useCurrentRoute, useNavigation} from 'react-navi';
import {FormattedMessage, useIntl} from 'react-intl';
import Logo from 'components/Logo';
import LanguageToggle from 'components/LanguageToggle';
import {isEmpty} from 'lodash';
import {useCurrentUser} from 'context';
import MenuIcon from 'mdi-material-ui/Menu';
import ExpandLessIcon from 'mdi-material-ui/ChevronUp';
import ExpandMoreIcon from 'mdi-material-ui/ChevronDown';
import CartArrowRightIcon from 'mdi-material-ui/CartArrowRight';
import AccountIcon from 'mdi-material-ui/Account';
import HomeIcon from 'mdi-material-ui/Home';
import CashIcon from 'mdi-material-ui/Cash';
import ContentCutIcon from 'mdi-material-ui/ContentCut';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import CommentQuestionOutlineIcon from 'mdi-material-ui/CommentQuestionOutline';
import {auth} from 'libs/firebase';
import useWindowSize from 'hooks/useWindowSize';
import Gravatar from 'react-gravatar';

export default function BottomMenu() {
    let classes = useStyles();
    let route = useCurrentRoute();
    let intl = useIntl();
    let navigation = useNavigation();
    let {currentUser} = useCurrentUser();
    let {windowSize} = useWindowSize();
    let [selected, setSelected] = useState(false);
    let [largePhone, setLargePhone] = useState(true);
    let [menuOpen, setMenuOpen] = useState(false);
    let [userMenuOpen, setUserMenuOpen] = useState(false);

    useEffect(() => {
        if (['/'].indexOf(route.url.pathname) !== -1) {
            setSelected(0);
        } else if (['/pricing/', '/tarieven/'].indexOf(route.url.pathname) !== -1) {
            setSelected(1);
        } else if (['/order/'].indexOf(route.url.pathname) !== -1) {
            setSelected(2);
        } else if (['/user/'].indexOf(route.url.pathname) !== -1) {
            setSelected(3);
        } else {
            setSelected(false);
        }
    }, [route.url.pathname]);

    useEffect(() => {
        if (windowSize.width > 350) {
            setLargePhone(true);
        } else {
            setLargePhone(false);
        }
    }, [windowSize.width]);

    let links = [
        {
            label: <FormattedMessage
                id="components.BottomMenu.home"
                defaultMessage={`Home`}
            />,
            url: '/',
            icon: <HomeIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.pricing"
                defaultMessage={`Pricing`}
            />,
            url: intl.formatMessage({
                id: 'url.pricing',
                defaultMessage: '/pricing/',
            }),
            icon: <CashIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.order"
                defaultMessage={`Order`}
            />,
            url: '/order/',
            icon: <CartArrowRightIcon />,
        },
    ];
    let drawerLinks = [
        {
            label: <FormattedMessage
                id="components.BottomMenu.home"
                defaultMessage={`Home`}
            />,
            url: '/',
            icon: <HomeIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.pricing"
                defaultMessage={`Pricing`}
            />,
            url: intl.formatMessage({
                id: 'url.pricing',
                defaultMessage: '/pricing/',
            }),
            icon: <CashIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.howItWorks"
                defaultMessage={`How it works`}
            />,
            url: intl.formatMessage({
                id: 'url.howItWorks',
                defaultMessage: '/how-it-works/',
            }),
            icon: <ContentCutIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.aboutUs"
                defaultMessage={`About us`}
            />,
            url: intl.formatMessage({
                id: 'url.aboutUs',
                defaultMessage: '/about-us/',
            }),
            icon: <AccountGroupIcon />,
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.contact"
                defaultMessage={`Contact`}
            />,
            url: '/contact/',
            icon: <CommentQuestionOutlineIcon />,
        },
    ];
    let userMenuLinks = [
        {
            label: <FormattedMessage
                id="components.BottomMenu.profile"
                defaultMessage={`Profile`}
            />,
            url: '/user/',
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.quotes"
                defaultMessage={`Quotes`}
            />,
            url: '/user/quotes/',
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.projects"
                defaultMessage={`Projects`}
            />,
            url: '/user/projects/',
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.invoices"
                defaultMessage={`Invoices`}
            />,
            url: '/user/invoices/',
        },
        {
            label: <FormattedMessage
                id="components.BottomMenu.tickets"
                defaultMessage={`Tickets`}
            />,
            url: '/user/tickets/',
        },
    ];

    return (
        <Hidden
            implementation="css"
            mdUp
        >
            <div
                id="bottomMenu"
                className={classes['root']}
            >
                <BottomNavigation
                    className={classes['__menu']}
                    value={selected}
                    showLabels
                >
                    {links.map((link, index) => (
                        <BottomNavigationAction
                            key={index}
                            label={link.label}
                            icon={link.icon}
                            classes={{
                                root: classes['__link'],
                                label: classes['__label'],
                                selected: classes['__label-selected'],
                            }}
                            component={Link}
                            href={link.url}
                        />
                    ))}
                    {(largePhone) ? (
                        <BottomNavigationAction
                            label={<FormattedMessage
                                id="components.BottomMenu.account"
                                defaultMessage={`Account`}
                            />}
                            icon={<AccountIcon />}
                            classes={{
                                root: classes['__link'],
                                label: classes['__label'],
                                selected: classes['__label-selected'],
                            }}
                            component={Link}
                            href={'/user/'}
                        />
                    ) : null}
                    <BottomNavigationAction
                        id="menuLink"
                        label={<FormattedMessage
                            id="components.BottomMenu.more"
                            defaultMessage={`More`}
                        />}
                        icon={<MenuIcon />}
                        classes={{
                            root: classes['__link'],
                            label: classes['__label'],
                            selected: classes['__label-selected'],
                        }}
                        onClick={() => setMenuOpen(true)}
                    />
                </BottomNavigation>
                <Drawer
                    classes={{
                        paper: classes['__drawer'],
                    }}
                    anchor="left"
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={() => setMenuOpen(false)}
                        onKeyDown={() => setMenuOpen(false)}
                    >
                        <div className={classes['__logo']}>
                            <Logo sticky={true} />
                            <LanguageToggle drawer={true} />
                        </div>
                        <Divider />
                        <List>
                            {drawerLinks.map((link, index) => (
                                <ListItem
                                    button
                                    key={index}
                                    component={Link}
                                    href={link.url}
                                >
                                    <ListItemIcon className={classes['__drawer-icon']}>
                                        {link.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes['__drawer-label'],
                                        }}
                                        primary={link.label}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                    </div>
                    <List>
                        <ListItem
                            button
                            component={Link}
                            href={'/order/'}
                            onClick={() => setMenuOpen(false)}
                        >
                            <ListItemIcon className={classes['__drawer-icon']}>
                                <CartArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes['__drawer-label'],
                                }}
                                primary={<FormattedMessage
                                    id="components.BottomMenu.startProject"
                                    defaultMessage={`Start a Project`}
                                />}
                            />
                        </ListItem>
                        {(!isEmpty(currentUser)) ? (
                            <div>
                                <ListItem
                                    button
                                    onClick={() => {
                                        if (userMenuOpen) {
                                            setUserMenuOpen(false);
                                        } else {
                                            setUserMenuOpen(true);
                                        }
                                    }}
                                >
                                    <ListItemIcon className={classes['__drawer-icon']}>
                                        <div>
                                            {(currentUser.photoURL) ? (
                                                <Avatar
                                                    className={classes['__avatar']}
                                                    src={currentUser.photoURL}
                                                    alt={currentUser.displayName}
                                                />
                                            ) : (
                                                <Gravatar
                                                    className={classes['__gravatar']}
                                                    email={currentUser.email}
                                                    size={40}
                                                    rating="pg"
                                                    default="mp"
                                                />
                                            )}
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes['__drawer-label'],
                                        }}
                                        primary={<FormattedMessage
                                            id="components.BottomMenu.clientArea"
                                            defaultMessage={`Client Area`}
                                        />}
                                    />
                                    {(userMenuOpen) ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={userMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List
                                        className={classes['__drawer-submenu']}
                                        component="div"
                                        disablePadding
                                    >
                                        <div
                                            tabIndex={0}
                                            role="button"
                                            onClick={() => setMenuOpen(false)}
                                            onKeyDown={setMenuOpen}
                                        >
                                            {userMenuLinks.map((link, index) => (
                                                <ListItem
                                                    className={classes['__drawer-subitem']}
                                                    button
                                                    key={index}
                                                    component={Link}
                                                    href={link.url}
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes['__drawer-sublabel'],
                                                        }}
                                                        primary={link.label}
                                                    />
                                                </ListItem>
                                            ))}
                                        </div>
                                        <ListItem
                                            className={classes['__drawer-subitem']}
                                            button
                                            onClick={() => {
                                                auth.signOut();
                                                setMenuOpen();

                                                return navigation.navigate('/');
                                            }}
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classes['__drawer-sublabel'],
                                                }}
                                                primary={<FormattedMessage
                                                    id="components.BottomMenu.logout"
                                                    defaultMessage={`Logout`}
                                                />}
                                            />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </div>
                        ) : (
                            <ListItem
                                button
                                component={Link}
                                href={'/user/'}
                                onClick={() => setMenuOpen(false)}
                            >
                                <ListItemIcon className={classes['__drawer-icon']}>
                                    <div>
                                        <AccountIcon />
                                        {(typeof currentUser.photoURL !== 'undefined') ? (
                                            <Avatar
                                                className={classes['__avatar']}
                                                src={currentUser.photoURL}
                                                alt={currentUser.displayName}
                                            />
                                        ) : null}
                                    </div>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes['__drawer-label'],
                                    }}
                                    primary={<FormattedMessage
                                        id="components.BottomMenu.clientArea"
                                        defaultMessage={`Client Area`}
                                    />}
                                />
                            </ListItem>
                        )}
                    </List>
                </Drawer>
            </div>
        </Hidden>
    );
}
