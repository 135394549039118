import React from 'react';
import {Radio} from '@material-ui/core';
import RadioChecked from './RadioChecked';
import RadioUnchecked from './RadioUnchecked';

export default function (props) {
    return (
        <Radio
            icon={<RadioUnchecked />}
            checkedIcon={<RadioChecked />} {...props}
        />
    );
}
