import {useCallback, useState} from 'react';

export default function useTheme() {
    let [theme, setState] = useState('default');

    let setTheme = useCallback(
        (themeName) => {
            setState(themeName);
        }, [],
    );

    return {
        theme,
        setTheme,
    };
};
