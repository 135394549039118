import {createBrowserNavigation} from 'navi';
import React from 'react';
import ReactDOM from 'react-dom';
import {NaviProvider, View} from 'react-navi';
import NaviHelmetProvider from 'react-navi-helmet-async';
import routes from './routes';
import theme from 'theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import WebFont from 'webfontloader';
import TagManager from 'react-gtm-module';
import CoreConfig from 'configs/Core.config';
import * as serviceWorker from './serviceWorker';
import {Context} from 'react-img-webp';

WebFont.load({
    custom: {
        families: [
            'Source Sans Pro',
            'Source Serif Pro',
        ],
        urls: [
            'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap',
            'https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,700&display=swap',
        ],
    },
});

async function main() {
    let locale = (window.location.hostname.includes('directbasing.nl') || window.location.hostname.includes('localhost')) ? 'nl' : 'en';
    let navigation = createBrowserNavigation({
        routes,
        context: {
            currentUser: {},
            locale: locale,
        },
        state: window.__NAVI_STATE__,
        trailingSlash: 'add',
    });

    await navigation.getRoute();

    TagManager.initialize({
        gtmId: CoreConfig.google.tagManager[locale],
    });

    ReactDOM.hydrate(
        <NaviHelmetProvider>
            <NaviProvider navigation={navigation}>
                <Context.WebPController>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <View />
                    </ThemeProvider>
                </Context.WebPController>
            </NaviProvider>
        </NaviHelmetProvider>,
        document.getElementById('root'),
    );

    serviceWorker.register({
        onUpdate: (registration) => {
            let waitingServiceWorker = registration.waiting;

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener('statechange', (event) => {
                    if (event.target.state === 'activated') {
                        document.addEventListener('visibilitychange', () => {
                            window.location.reload();
                        });
                    }
                });

                waitingServiceWorker.postMessage({
                    type: 'SKIP_WAITING',
                });
            }
        },
    });
}

window.onerror = (error) => {
    if (
        error.indexOf('An internal error was encountered in the Indexed Database server') >= 0 ||
        error.indexOf('AsyncQueue is already failed') >= 0
    ) {
        console.log('Refresh a way out of error, unfortunately is the only way out...');

        window.location.reload();
    }
};

main();
