import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            width: '100%',
        },
        'form': {
            padding: '20px 20px 0',
            width: '100%',
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.lightGray,
            [theme.breakpoints.down('sm')]: {
                padding: '15px 15px 0',
            },
        },
        'form--full-width': {
            padding: 0,
        },
        'form--dark': {
            background: 'none',
        },
        '__fieldset': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        '__error': {
            marginBottom: 20,
            padding: 10,
            fontWeight: 600,
            color: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius / 2,
            background: theme.palette.error.main,
        },
        '__select': {
            margin: '0 0 20px 0',
            width: 'calc(50% - 10px)',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& > legend': {
                paddingTop: 1,
                paddingBottom: 8,
                fontSize: 18,
            },
        },
        '__select--full-width': {
            marginRight: 0,
            width: '100%',
        },
        '__select-root': {
            marginTop: 9,
            '& > svg': {
                right: 5,
            },
        },
        '__select-input': {
            padding: '0 10px',
            display: 'flex',
            alignItems: 'center',
            height: 50,
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: 3,
            background: theme.palette.common.white,
            '&:focus': {
                borderRadius: 3,
                background: theme.palette.common.white,
            },
        },
        '__textfield': {
            margin: '0 0 20px 0',
            width: 'calc(50% - 10px)',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 15,
                width: '100%',
            },
        },
        '__textfield--full-width': {
            marginRight: 0,
            width: '100%',
        },
        '__textarea': {
            marginTop: 10,
            padding: 10,
            height: 150,
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius / 2,
            background: theme.palette.common.white,
        },
        '__input': {
            marginTop: 20,
            padding: '0 10px',
            height: 50,
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius / 2,
            background: theme.palette.common.white,
            [theme.breakpoints.down('sm')]: {
                marginTop: 15,
            },
        },
        '__input-label': {
            fontWeight: 600,
            fontSize: 25,
            color: theme.palette.common.blue,
        },
        '__input-label--dark': {
            color: theme.palette.common.white,
        },
        '__button': theme.gutter.sm.margin.top,
    }),
    {
        name: 'Form',
    },
);
