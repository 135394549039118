import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            position: 'fixed',
            top: 0,
            left: 0,
            overflow: 'hidden',
            width: '100vw',
            height: '100vh',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                width: 'auto',
                height: 'auto',
            },
        },
        '__content': {
            position: 'absolute',
            top: '40%',
            left: '50%',
            zIndex: 2,
            transform: 'translate(-50%, -50%)',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                top: 0,
                left: 0,
                transform: 'none',
            },
        },
        '__buttons': {
            position: 'relative',
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '__animation': {
            position: 'absolute',
            bottom: 150,
            left: '50%',
            width: 300,
            height: 350,
            transform: 'translateX(-50%)',
            [theme.breakpoints.down('lg')]: {
                bottom: 100,
            },
            [theme.breakpoints.down('md')]: {
                bottom: 50,
            },
        },
        '__rocket': {
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'scale(1) rotate(165deg)',
        },
        '__smoke': {
            position: 'absolute',
            bottom: 0,
            left: 105,
            zIndex: '3',
            width: 1,
            height: 160,
            '& > span': {
                position: 'absolute',
                bottom: 50,
                left: 'calc(50% + 20px)',
                opacity: 0,
                display: 'block',
                width: 0,
                height: 0,
                border: `35px solid ${theme.palette.common.darkestBlue}`,
                borderRadius: 35,
                transform: 'scale(0.2)',
            },
        },
        '__smoke-0': {
            animation: '$smokeL 10s 0s infinite',
        },
        '__smoke-1': {
            animation: '$smokeR 10s 1s infinite',
        },
        '__smoke-2': {
            animation: '$smokeL 10s 2s infinite',
        },
        '__smoke-3': {
            animation: '$smokeR 10s 3s infinite',
        },
        '__smoke-4': {
            animation: '$smokeL 10s 4s infinite',
        },
        '__smoke-5': {
            animation: '$smokeR 10s 5s infinite',
        },
        '__smoke-6': {
            animation: '$smokeL 10s 6s infinite',
        },
        '__smoke-7': {
            animation: '$smokeR 10s 7s infinite',
        },
        '__smoke-8': {
            animation: '$smokeL 10s 8s infinite',
        },
        '__smoke-9': {
            animation: '$smokeR 10s 9s infinite',
        },
        '@keyframes smokeL': {
            '0%': {
                transform: 'scale(0.2) translate(0, 0)',
            },
            '10%': {
                opacity: 1,
                transform: 'scale(0.2) translate(0, -5px)',
            },
            '100%': {
                opacity: 0,
                transform: 'scale(1) translate(-20px, -130px)',
            },
        },
        '@keyframes smokeR': {
            '0%': {
                transform: 'scale(0.2) translate(0, 0)',
            },
            '10%': {
                opacity: 1,
                transform: 'scale(0.2) translate(0, -5px)',
            },
            '100%': {
                opacity: 0,
                transform: 'scale(1) translate(20px, -130px)',
            },
        },
    }),
    {
        name: 'NotFound',
    },
);
