import {makeStyles} from '@material-ui/styles';

export default makeStyles((theme) => ({
        'root': {
            border: `1px solid ${theme.palette.common.purple}`,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white,
        },
        '__select': {
            minWidth: '100%',
            fontSize: 18,
        },
        '__select-root': {
            '& > svg': {
                right: 5,
            },
        },
        '__select-input': {
            padding: '0 10px',
            display: 'flex',
            alignItems: 'center',
            height: 50,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white,
        },
    }),
    {
        name: 'SelectGroupField',
    },
);
