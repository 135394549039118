import {createContext, useContext} from 'react';

export const CurrentUserContext = createContext({
    authenticating: true,
    currentUser: {},
});

export function useCurrentUser() {
    return useContext(CurrentUserContext);
}

export const LocaleContext = createContext({
    locale: 'en',
});

export function useLocale() {
    return useContext(LocaleContext);
}

export const ThemeContext = createContext({
    theme: 'default',
});

export function useTheme() {
    return useContext(ThemeContext);
}
