import {each, merge as lodashMerge, sortBy} from 'lodash';

const getBreakpoints = {
    xs: 0,
    sm: 700,
    md: 960,
    lg: 1280,
    xl: 1920,
};

const capitalize = (string) => {
    if (typeof string !== 'string') {
        return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
};

const getVariants = (variant, theme, size, breakpoints) => {
    let directions = ['top', 'right', 'bottom', 'left'];
    let css = {
        all: {
            [variant]: size,
        },
    };

    Object.keys(breakpoints).map((breakpoint) => {
        if (breakpoint === 'sm' && variant === 'padding') {
            css.all[theme.breakpoints.down(breakpoint)] = {
                [variant]: `${breakpoints[breakpoint] * 2}px ${breakpoints[breakpoint]}px`,
            };
        } else {
            css.all[theme.breakpoints.down(breakpoint)] = {
                [variant]: breakpoints[breakpoint],
            };
        }

        return breakpoint;
    });

    directions.map((direction) => {
        css[direction] = {
            [`${variant}${capitalize(direction)}`]: size,
        };

        Object.keys(breakpoints).map((breakpoint) => {
            css[direction][theme.breakpoints.down(breakpoint)] = {
                [`${variant}${capitalize(direction)}`]: breakpoints[breakpoint],
            };

            return breakpoint;
        });

        return direction;
    });

    return css;
};

const getPosition = (theme, size, breakpoints) => {
    let directions = ['top', 'right', 'bottom', 'left'];
    let css = {};

    directions.map((direction) => {
        css[direction] = {
            [direction]: size,
        };

        Object.keys(breakpoints).map((breakpoint) => {
            css[direction][theme.breakpoints.down(breakpoint)] = {
                [direction]: breakpoints[breakpoint],
            };

            return breakpoint;
        });

        return direction;
    });

    return css;
};

const getGutter = (theme, size, breakpoints) => {
    return {
        margin: getVariants('margin', theme, size, breakpoints),
        padding: getVariants('padding', theme, size, breakpoints),
        position: getPosition(theme, size, breakpoints),
    };
};

const merge = (object1 = {}, object2 = {}, object3 = {}, object4 = {}, object5 = {}, object6 = {}, object7 = {}, object8 = {}, object9 = {}) => {
    let sortedCss = {};
    let cssObject = lodashMerge(object1, object2, object3, object4, object5, object6, object7, object8, object9);
    let sortedKeys = sortBy(Object.keys(cssObject), (key) => {
        return key;
    }).reverse();

    each(sortedKeys, (key) => {
        sortedCss[key] = cssObject[key];
    });

    return sortedCss;
};

const maxWidth = (width) => {
    if (getBreakpoints[width]) {
        return `@media (max-width: ${getBreakpoints[width]}px)`;
    }

    return `@media (max-width: ${width}px)`;
};

const minWidth = (width) => {
    if (getBreakpoints[width]) {
        return `@media (min-width: ${getBreakpoints[width]}px)`;
    }

    return `@media (min-width: ${width}px)`;
};

const betweenWidth = (minWidth, maxWidth) => {
    if (getBreakpoints[minWidth]) {
        minWidth = getBreakpoints[minWidth];
    }

    if (getBreakpoints[maxWidth]) {
        maxWidth = getBreakpoints[maxWidth];
    }

    return `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
};

export {
    getGutter,
    getBreakpoints,
    merge,
    maxWidth,
    minWidth,
    betweenWidth,
};
