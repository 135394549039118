import React from 'react';
import {Helmet} from 'react-helmet-async';
import HeadNL from 'App.head.nl';
import HeadEN from 'App.head.en';

export default function ({locale}) {
    let appleTouchIconSizes = [
        '57x57',
        '72x72',
        '76x76',
        '114x114',
        '120x120',
        '144x144',
        '152x152',
        '180x180',
    ];
    let appleTouchStartupImages = [
        {
            name: 'iphone5_splash.png',
            media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'iphone6_splash.png',
            media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'iphoneplus_splash.png',
            media: '(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)',
        }, {
            name: 'iphonex_splash.png',
            media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
        }, {
            name: 'iphonexr_splash.png',
            media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'iphonexsmax_splash.png',
            media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)',
        }, {
            name: 'ipad_splash.png',
            media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'ipadpro1_splash.png',
            media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'ipadpro3_splash.png',
            media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)',
        }, {
            name: 'ipadpro2_splash.png',
            media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)',
        },
    ];

    return (
        <>
            <Helmet>
                <html lang={locale} />
                <title>Direct Basing®</title>

                <meta charSet="utf-8" />

                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta
                    name="apple-mobile-web-app-capable"
                    content="yes"
                />
                <meta
                    name="apple-mobile-web-app-title"
                    content="Direct Basing"
                />
                <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="black"
                />
                <meta
                    name="theme-color"
                    content="#73c23a"
                />
                <meta
                    name="format-detection"
                    content="telephone=no"
                />
                <meta
                    name="HandheldFriendly"
                    content="true"
                />
                <meta
                    name="MobileOptimized"
                    content="375"
                />
                <meta
                    name="mobile-web-app-capable"
                    content="yes"
                />

                <link
                    rel="icon"
                    type="image/x-icon"
                    href="/images/icons/icon-128x128.png"
                />
                <link
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/images/icons/icon-128x128.png"
                />
                <link
                    rel="apple-touch-startup-image"
                    href="/images/launch.png"
                />

                <link
                    rel="apple-touch-icon"
                    href="/images/icons/apple-touch-icon.png"
                />

                {(appleTouchIconSizes.map((size, index) => (
                    <link
                        key={index}
                        rel="apple-touch-icon"
                        sizes={size}
                        href={`/images/icons/apple-touch-icon-${size}.png`}
                    />
                )))}

                {(appleTouchStartupImages.map((startupImage, index) => (
                    <link
                        key={index}
                        rel="apple-touch-startup-image"
                        href={`/images/splashscreens/${startupImage.name}`}
                        media={startupImage.media}
                    />
                )))}
            </Helmet>
            {(locale === 'nl') ? (
                <HeadNL />
            ) : (
                <HeadEN />
            )}
        </>
    );
}
