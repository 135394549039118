import React, {useEffect, useState} from 'react';
import useStyles from './Header.styles';
import {FormattedMessage} from 'react-intl';
import {Link, useCurrentRoute} from 'react-navi';
import classNames from 'classnames';
import Logo from 'components/Logo';
import {isEmpty} from 'lodash';
import {Button, Hidden} from '@material-ui/core';
import UserMenu from 'components/Header/UserMenu';
import LoginButton from 'components/LoginButton';
import LanguageToggle from 'components/LanguageToggle';
import MainMenu from 'components/Header/MainMenu';
import {useCurrentUser} from 'context';
import MenuIcon from 'mdi-material-ui/Menu';
import {getBreakpoints} from 'helpers/styles';
import useWindowSize from 'hooks/useWindowSize';

export default function Header() {
    let classes = useStyles();
    let route = useCurrentRoute();
    let {currentUser} = useCurrentUser();
    let {windowSize} = useWindowSize();
    let [sticky, setSticky] = useState(false);

    useEffect(() => {
        function onChange(event) {
            // Don't show a sticky header on specific pages
            if (
                route.url.pathname.includes('/order/') ||
                route.url.pathname.includes('/how-it-works/') ||
                route.url.pathname.includes('/werkwijze/')
            ) {
                setSticky(false);
                return;
            }

            let scrollTop = event.srcElement.scrollingElement.scrollTop;
            let header = document.getElementById('header');

            if (header && windowSize.width > getBreakpoints.sm && scrollTop > header.clientHeight) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        }

        window.addEventListener('scroll', onChange);

        return () => {
            window.removeEventListener('scroll', onChange);
        };
    }, [route.url.pathname, windowSize.width]);

    return (
        <header
            id="header"
            className={(sticky) ? classNames(classes['root'], classes['--sticky']) : classes['root']}
        >
            <Logo sticky={sticky} />
            <div className={(sticky) ? classNames(classes['__topbar'], classes['--compact']) : classes['__topbar']}>
                {(!sticky) ? (
                    <span className={classes['__toggles']}>
                        {(!isEmpty(currentUser)) ? (
                            <Hidden xsDown>
                                <UserMenu />
                            </Hidden>
                        ) : (
                            <LoginButton />
                        )}
                        <Hidden xsDown>
                            <LanguageToggle />
                        </Hidden>
                    </span>
                ) : (
                    <span className={classes['__toggles']}>
                        {(isEmpty(currentUser)) ? (
                            <LoginButton />
                        ) : null}
                    </span>
                )}
                <Hidden
                    implementation="css"
                    smUp
                >
                    <Button
                        className={classes['__menu-button']}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => document.getElementById('menuLink').click()}
                    >
                        <MenuIcon className={classes['__menu-icon']} />
                    </Button>
                </Hidden>
                <Hidden xsDown>
                    <Button
                        className={classes['__project-button']}
                        variant="contained"
                        size="small"
                        color="secondary"
                        component={Link}
                        href="/order/"
                    >
                        <FormattedMessage
                            id="components.Header.startProject"
                            defaultMessage={`Start a Project`}
                        />
                    </Button>
                </Hidden>
            </div>
            <Hidden xsDown>
                <MainMenu />
            </Hidden>
        </header>
    );
}
