import React from 'react';
import useStyles from './LoginForm.styles';
import {FormattedMessage} from 'react-intl';
import Form from 'components/Form';
import {auth} from 'libs/firebase';
import useEvent from 'hooks/useEvent';
import useForm from 'hooks/useForm';

export default function LoginForm({popupHandler, reauthenticateHandler}) {
    let classes = useStyles();
    let {createEvent} = useEvent();
    let {fields, loading, values, errors, handleOnChange, handleOnBlur, handleOnSubmit} = useForm(
        {
            emailAddress: {
                label:
                    <FormattedMessage
                        id="components.LoginButton.LoginForm.emailAddress"
                        defaultMessage={`Email Address`}
                    />,
                type: 'email',
                fullWidth: true,
                required: true,
                autoComplete: 'email',
            },
            password: {
                label:
                    <FormattedMessage
                        id="components.LoginButton.LoginForm.password"
                        defaultMessage={`Password`}
                    />,
                type: 'password',
                fullWidth: true,
                required: true,
                autoComplete: 'current-password',
            },
        },
        async (values) => {
            await auth.signInWithEmailAndPassword(values.emailAddress, values.password);

            // Reauthenticate for sensitive profile changes
            if (typeof reauthenticateHandler !== 'boolean' && typeof reauthenticateHandler === 'function') {
                reauthenticateHandler(values.emailAddress, values.password);
            } else {
                // Logged in
                createEvent('LOGGED_IN');
            }

            // Close popup
            if (popupHandler) {
                popupHandler(false);
            }
        },
    );

    return (
        <div className={classes['root']}>
            <Form
                changeHandler={handleOnChange}
                validationHandler={handleOnBlur}
                submitHandler={handleOnSubmit}
                fields={fields}
                values={values}
                errors={errors}
                loading={loading}
                button={{
                    id: 'loginFormButton',
                    color: 'secondary',
                    label: <FormattedMessage
                        id="components.LoginButton.LoginForm.login"
                        defaultMessage={`Login`}
                    />,
                    class: classes['__button'],
                }}
            />
        </div>
    );
}
