import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': ({sticky}) => {
            let style = merge({
                    paddingLeft: 70,
                    position: 'absolute',
                    display: 'flex',
                    height: 60,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textDecoration: 'none',
                    fontStyle: 'italic',
                    fontSize: 16,
                    color: theme.palette.common.white,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: 50,
                        height: 40,
                    },
                },
                theme.gutter.md.position.top,
                theme.gutter.md.position.left,
            );

            if (sticky) {
                merge(style, {
                    top: ['50%', '!important'],
                    height: [50, '!important'],
                    fontSize: 14,
                    transform: 'translateY(-50%)',
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: 55,
                        height: 40,
                    },
                    '& img': {
                        top: 3,
                        width: 50,
                        height: 50,
                        [theme.breakpoints.down('sm')]: {
                            width: 40,
                            height: 40,
                        },
                    },
                    [theme.breakpoints.between('sm', 850)]: {
                        '& strong': {
                            display: 'none',
                        },
                        '& span': {
                            display: 'none',
                        },
                    },
                });
            }

            return style;
        },
        '__logo': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 60,
            height: 60,
            [theme.breakpoints.down('sm')]: {
                width: 40,
                height: 40,
            },
        },
        '__name': {
            display: 'block',
            fontStyle: 'normal',
            fontSize: 26,
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
            },
        },
    }),
    {
        name: 'Logo',
    },
);
