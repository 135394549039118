import React from 'react';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import useStyles from './Title.styles';

export default function ({title, subtitle, button, titleComponent = 'h1', subtitleComponent = 'p'}) {
    let classes = useStyles();

    return (
        <div className={(button) ? classNames(classes['root'], classes['--has-button']) : classes['root']}>
            <Typography
                className={classes['__title']}
                variant="h1"
                component={titleComponent}
            >
                {title}
            </Typography>
            {subtitle ? (
                <Typography
                    className={classes['__subtitle']}
                    variant="subtitle1"
                    component={subtitleComponent}
                >
                    {subtitle}
                </Typography>
            ) : null}
            {button ? (
                <div className={classes['__button']}>
                    {button}
                </div>
            ) : null}
        </div>
    );
}
