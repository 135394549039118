import {makeStyles} from '@material-ui/styles';
import {merge} from 'helpers/styles';

export default makeStyles((theme) => ({
        'root': merge({
                margin: '0 auto',
                maxWidth: 900,
                textAlign: 'center',
                [theme.breakpoints.down('sm')]: {
                    paddingRight: theme.gutters.xs,
                    paddingLeft: theme.gutters.xs,
                },
                '&:last-child': {
                    marginBottom: [0, '!important'],
                },
            },
            theme.gutter.md.margin.bottom,
            {
                [theme.breakpoints.down('sm')]: theme.gutter.xl.margin.bottom,
            },
        ),
        '--has-button': {
            maxWidth: theme.widthBase,
            position: 'relative',
        },
        '__title': merge({
                color: theme.palette.common.white,
                textShadow: `0 0 10px ${theme.palette.common.darkBlue}`,
            },
            theme.gutter.sm.margin.bottom,
        ),
        '__subtitle': {
            fontWeight: 'normal',
            color: theme.palette.common.lightGray,
            textShadow: `0 0 10px ${theme.palette.common.darkBlue}`,
        },
        '__button': {
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
        },
    }),
    {
        name: 'Title',
    },
);
